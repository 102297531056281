import { getAxiosInstance } from '../configs/axios.config';
import { createContext } from 'react';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_STORE_PROD_STOCK_PROGRESS = "GET_STORE_PROD_STOCK_PROGRESS";
const GET_STORE_PROD_STOCK_SUCCESS = "GET_STORE_PROD_STOCK_SUCCESS";
const GET_STORE_PROD_STOCK_FAILURE = "GET_STORE_PROD_STOCK_FAILURE";

export const getStoreProdStocks = (data, dispatch) => {
    dispatch({ type: GET_STORE_PROD_STOCK_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_PROD_STOCK, { params: { storeId: user.storeId, stock: data.stock } })
        .then(function (response) {
            dispatch({ type: GET_STORE_PROD_STOCK_SUCCESS, productStocks: response.data.dataList, totalItems: response.data.totalItems });
        }).catch(function (error) {
            dispatch({ type: GET_STORE_PROD_STOCK_FAILURE });
        });
}

export const getNewStoreProd = (dispatch) => {
    dispatch({ type: GET_STORE_PROD_STOCK_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_NEW_PRODUCT)
        .then(function (response) {
            dispatch({ type: GET_STORE_PROD_STOCK_SUCCESS, productStocks: response.data.dataList, totalItems: response.data.totalItems });
        }).catch(function (error) {
            dispatch({ type: GET_STORE_PROD_STOCK_FAILURE });
        });
}

let initialState = {
    productStocks: [],
    totalItems: 0,
    isInProgress: false,
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_STORE_PROD_STOCK_PROGRESS:
            return { ...state, productStocks: [] };
        case GET_STORE_PROD_STOCK_SUCCESS:
            return { ...state, productStocks: action.productStocks, totalItems: action.totalItems };
        case GET_STORE_PROD_STOCK_FAILURE:
            return { ...state };
        default:
            return state;
    }
}

export default reducer;