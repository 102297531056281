import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_STORE_ORDERS_PROGRESS = "GET_STORE_ORDERS_PROGRESS";
const GET_STORE_ORDERS_SUCCESS = "GET_STORE_ORDERS_SUCCESS";
const GET_STORE_ORDERS_FAILURE = "GET_STORE_ORDERS_FAILURE";

const STORE_ORDER_RECEIVE_PROGRESS = "STORE_ORDER_RECEIVE_PROGRESS";
const STORE_ORDER_RECEIVE_SUCCESS = "STORE_ORDER_RECEIVE_SUCCESS";
const STORE_ORDER_RECEIVE_FAILURE = "STORE_ORDER_RECEIVE_FAILURE";

export const getStoreOrders = (dispatch) => {
    dispatch({ type: GET_STORE_ORDERS_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_PRODUCT_ORDERS, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_STORE_ORDERS_SUCCESS, storeOrders: response.data.dataList, totalItems: response.data.totalItems });
        }).catch(function (error) {
            dispatch({ type: GET_STORE_ORDERS_FAILURE });
        });
}

export const storeOrderReceive = (dispatch, order) => {
    dispatch({ type: STORE_ORDER_RECEIVE_PROGRESS, isInProgress: true });

    let axios = getAxiosInstance();

    axios.put(apiConfig.api.STORE_ORDER_RECEIVE, { id: order._id })
        .then(function (response) {
            dispatch({ type: STORE_ORDER_RECEIVE_SUCCESS, orderReceive: true, isInProgress: false });
        }).catch(function (error) {
            dispatch({ type: STORE_ORDER_RECEIVE_FAILURE, isInProgress: false });
        });
}

let initialState = {
    storeOrders: [],
    totalItems: 0,
    isInProgress: false,
    orderReceive: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_STORE_ORDERS_PROGRESS:
            return { ...state, orderReceive: false };
        case GET_STORE_ORDERS_SUCCESS:
            return { ...state, storeOrders: action.storeOrders, totalItems: action.totalItems };
        case GET_STORE_ORDERS_FAILURE:
            return { ...state };

        case STORE_ORDER_RECEIVE_PROGRESS:
            return { ...state, isInProgress: action.isInProgress };
        case STORE_ORDER_RECEIVE_SUCCESS:
            return { ...state, orderReceive: action.orderReceive, isInProgress: action.isInProgress };
        case STORE_ORDER_RECEIVE_FAILURE:
            return { ...state, isInProgress: action.isInProgress };

        default:
            return state;
    }
}

export default reducer;