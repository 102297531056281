import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    Paper, AppBar, IconButton, Toolbar, Typography, Grid, Card, CardContent, ListItem, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, ListItemText, ListItemIcon
} from '@material-ui/core';
import { ArrowBackIos, PersonPin, TrendingUp, Equalizer, Assessment } from '@material-ui/icons';
import Progress from './progress';
import Snackbar from './snackbar';
import { getFullDate } from '../configs/date.config';
import { makeStyles } from '@material-ui/core/styles';
import { authDetails } from '../auth/authdetails';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', width: '97%', whiteSpace: 'nowrap', color: 'black', fontSize: '23px'
    },
}));

function Stats() {
    const [openDemandProduct, setOpenDemandProduct] = React.useState(false);
    const [bgColor, setbgColor] = React.useState(0);
    const [quantity, setQuantity] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const productDemand = useSelector(state => state.productDemand);
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = authDetails();

    const handleClickOpen = () => {
        setOpenDemandProduct(true);
    };

    const handleClose = () => {
        setOpenDemandProduct(false);
    };

    const getData = (no) => {
        setbgColor(no);
    }

    const goBack = () => {
        window.history.back();
    }

    return (
        <React.Fragment>
            {productDemand.isInProgress && <Progress open={productDemand.isInProgress} />}
            {/* {<Snackbar message={productDemand.message} open={productDemand.message ? true : false} />} */}
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}>Statistics</Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="row" style={{paddingTop: '10px'}}>
                <Grid container style={{ backgroundColor: '#cbcbcb', margin: '0px 10px', borderRadius: '10px' }} item xs={12}>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 0 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 0 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(0)}>D</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 1 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 1 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(1)}>W</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 2 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 2 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(2)}>M</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 3 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 3 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(3)}>Y</Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '10px' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Paper style={{ boxShadow: 'unset' }}>
                        <Grid container direction="row">
                            <Grid item xs={4} style={{ paddingRight: '10px' }}><Paper style={{ backgroundColor: '#ffe50066', padding: '5px' }}>
                                <ListItem style={{ padding: '0px' }}>
                                    <ListItemIcon style={{ minWidth: '28px' }}><Assessment /></ListItemIcon>
                                    <ListItemText primary={<span style={{ fontSize: '13px' }}>Sales</span>} />
                                </ListItem><div>123054</div></Paper></Grid>
                            <Grid item xs={4} style={{ paddingRight: '10px' }}><Paper style={{ backgroundColor: '#e91e6336', padding: '5px' }}>
                                <ListItem style={{ padding: '0px' }}>
                                    <ListItemIcon style={{ minWidth: '28px' }}><Equalizer /></ListItemIcon>
                                    <ListItemText primary={<span style={{ fontSize: '13px' }}>Orders</span>} />
                                </ListItem><div>123054</div></Paper></Grid>
                            <Grid item xs={4}><Paper style={{ backgroundColor: '#03a9f429', padding: '5px' }}>
                                <ListItem style={{ padding: '0px' }}>
                                    <ListItemIcon style={{ minWidth: '28px' }}><TrendingUp /></ListItemIcon>
                                    <ListItemText primary={<span style={{ fontSize: '13px' }}>Profit</span>} />
                                </ListItem><div>123054</div></Paper></Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid >
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP CATEGORIES</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>CATEGORY NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {productDemand.productDemands.map((product) => {
                            return (
                                <Grid item xs={12} sm={12} md={12}>

                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP PRODUCTS</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>PRODUCT NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {productDemand.productDemands.map((product) => {
                            return (
                                <Grid item xs={12} sm={12} md={12}>

                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP CUSTOMER</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>CUSTOMER NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {productDemand.productDemands.map((product) => {
                            return (
                                <Grid item xs={12} sm={12} md={12}>

                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            {
                openDemandProduct && <Dialog open={openDemandProduct} onClose={handleClose} aria-labelledby="demand-product">
                    <DialogTitle id="demand-product" style={{ color: '#FF5722' }}>उत्पादनाची मागणी करा</DialogTitle>
                </Dialog>
            }
        </React.Fragment >

    );
}

export default Stats;