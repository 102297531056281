module.exports = {
    api: {
        DEMAND_PRODUCT: '/add-product-demand',
        GET_STORE_PRODUCT_DEMANDS: '/get-store-product-demands',
        GET_STORE_PRODUCT_ORDERS: '/get-store-product-orders',
        STORE_ORDER_RECEIVE: '/deliver-store-product-order',
        GET_STORE_CUST_ORDERS: '/get-store-all-cust-orders',
        LOGIN: '/login-store-user',
        GET_STORE_PROD_STOCK: '/get-store-prod-stock',
        GET_NEW_PRODUCT: '/get-new-product',
        GET_PROMOTIONAL_OFFERS: '/promotional-offer',
        GET_STORE_PROD_LIST: '/get-store-prod-list',
        SAVE_ORDER: '/save-order',
        RECIVED_ORDER: '/recive-order',
        RECIVED_PAYMENT: '/recive-payment',
        GET_STORE_COUNTER_ORDERS: 'get-store-counter-orders',
        GET_STORE_NAME: 'get-store-name',
        CANCEL_ORDER: 'cancel-cust-order',
        DELIVER_ORDER: 'deliver-cust-order',
        CHANGE_PASSWORD: 'update-user-password',
        GET_STORE_SELL_ORDERS: 'sell-count',
        GET_SELL_PRODUCTS: 'sell-by-product',
        GET_SELL_CUST: 'sell-by-customer',
    },
    appRoutes: {
        HOME: '/',
        MY_COUNTER: '/my-counter',
        STORE_ORDERS: '/store-orders',
        MY_SELL: '/my-sell',
        CUST_ONLINE_ORDERS: '/cust-online-orders',
        COUNTER_ORDERS: '/counter-orders',
        STATS: '/stats',
        PRODUCT_ORDERS: '/product-orders',
        VIEW_PRODUCT: '/view-product',
        CART: '/view-cart',
        OFFER_PRODUCTS: '/product-offers',
        LOGIN: '/login',
        SHOPPING: '/shopping',
        SETTINGS: '/settings',
        NOTIFICATION: '/notification',
    },
    constants: {
        STORE_USER: 'storeuser',
        PRODUCT_LIST: 'products',
    }
}



