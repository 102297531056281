import { decrypt, encrypt } from '../configs/crypto';

export function authDetails() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('storeuser'));
    if (user) {
        return user;
    } else {
        return undefined;
    }
};
