import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger'
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { history } from '../helpers/history';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import appReducer from "./app.reducer";
import loginReducer from "./login.reducer";
import homeReducer from "./home.reducer";
import productDemandReducer from "./product.demand.reducer";
import mySellProfitReducer from "./my.sell.reducer";
import storeOrderReducer from "./store.order.reducer";
import custOrderReducer from "./cust.order.reducer";
import storeProdStockReducer from "./store.prod.stock.reducer";
import productOrdersReducer from "./product.orders.reducer";
import promotionalOffersReducer from "./promotional.offers.reducer";
import offerProducts from "./offer.products.reducer";

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddlewareResult = routerMiddleware(history);

const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
        return compose(applyMiddleware(thunkMiddleware, routerMiddlewareResult));
    } else {
        // Enable additional logging in non-production environments.
        return compose(applyMiddleware(thunkMiddleware, routerMiddlewareResult, createLogger()));
    }
};

export default function configureStore() {
    return createStore(
        combineReducers({
            app: appReducer,
            login: loginReducer,
            homeData: homeReducer,
            productDemand: productDemandReducer,
            mySellProfit: mySellProfitReducer,
            storeOrder: storeOrderReducer,
            custOrder: custOrderReducer,
            productStock: storeProdStockReducer,
            promotionalOffers: promotionalOffersReducer,
            productOrders: productOrdersReducer,
            offerProducts: offerProducts,
        }),
        composeWithDevTools(getMiddleware())
    )
}