import { getAxiosInstance } from '../configs/axios.config';

const { constants, api } = require('../configs/constants');

const LOGIN_PROGRESS = "LOGIN_PROGRESS";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";

const SET_MESSAGE = 'SET_MESSAGE';

export const login = (data, dispatch) => {
    dispatch({ type: LOGIN_PROGRESS });

    let axios = getAxiosInstance();
    axios.post(api.LOGIN, {
        mobileNo: data.mobileNo,
        password: data.password
    }).then(function (response) {
        localStorage.setItem(constants.STORE_USER, JSON.stringify(response.data));
        dispatch({ type: LOGIN_SUCCESS, storeUser: response.data });
    }).catch(function (error) {
        dispatch({ type: LOGIN_FAILURE });
    });
}

let initialState = {
    storeUser: null,
    isInProgress: false,
    message: null
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_PROGRESS:
            return { ...state, isInProgress: true };
        case LOGIN_SUCCESS:
            return { ...state, storeUser: action.storeUser, isInProgress: false };
        case LOGIN_FAILURE:
            return { ...state, isInProgress: false };
        case SET_MESSAGE:
            return { ...state, message: action.message };
        default:
            return state;
    }
}

export default reducer;