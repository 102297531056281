import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSellOrdersCount, getStoreSellsByProduct, getStoreSellsByCustomer } from "../reducers/my.sell.reducer"
import {
    Paper, AppBar, IconButton, Toolbar, Typography, Grid, Card, CardContent, ListItem, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, ListItemText, ListItemIcon
} from '@material-ui/core';
import { ArrowBackIos, PersonPin, TrendingUp, Equalizer, Assessment } from '@material-ui/icons';
import Progress from './progress';
import { makeStyles } from '@material-ui/core/styles';
import { authDetails } from '../auth/authdetails';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', width: '97%', whiteSpace: 'nowrap', color: 'black', fontSize: '23px'
    },
    fabButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px'
    },
    productName: {
        lineHeight: 1.2,
        fontSize: '14px',
        color: 'black',
        maxHeight: '44px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        webkitLineClamp: '2',
        display: '-webkit-box',
        webkitBoxOrient: 'vertical'
    },
    quantity: {
        fontSize: '12px',
        color: '#535353',
        marginTop: '5px',
        msTextOverflow: 'ellipsis',
        textOverflow: 'ellipsis'
    },
}));

function MySells() {
    const [openDemandProduct, setOpenDemandProduct] = React.useState(false);
    const [bgColor, setbgColor] = React.useState(0);
    const mySellProfit = useSelector(state => state.mySellProfit);
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = authDetails();

    useEffect(() => {
        getSellOrdersCount(dispatch);
        getStoreSellsByProduct(dispatch);
        getStoreSellsByCustomer(dispatch);
    }, []);

    const handleClose = () => {
        setOpenDemandProduct(false);
    };

    const getData = (no) => {
        setbgColor(no);
    }

    const goBack = () => {
        window.history.back();
    }

    return (
        <React.Fragment>
            {mySellProfit.isInProgress && <Progress open={mySellProfit.isInProgress} />}
            {/* {<Snackbar message={mySellProfit.message} open={mySellProfit.message ? true : false} />} */}
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}>{user.name}</Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="row" style={{ padding: '10px 10px 0px' }}>
                <Grid item xs={4} style={{ textAlign: 'center' }}><PersonPin color="primary" style={{ fontSize: '40px' }} /></Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}><PersonPin color="primary" style={{ fontSize: '40px' }} /></Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}><PersonPin color="primary" style={{ fontSize: '40px' }} /></Grid>
            </Grid>
            <Grid container direction="row">
                <Grid container style={{ backgroundColor: '#cbcbcb', margin: '0px 10px', borderRadius: '10px' }} item xs={12}>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 0 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 0 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(0)}>D</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 1 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 1 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(1)}>W</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 2 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 2 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(2)}>M</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 3 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 3 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }} onClick={(e) => getData(3)}>Y</Grid>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '10px' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Paper style={{ boxShadow: 'unset' }}>
                        <Grid container direction="row">
                            <Grid item xs={4} style={{ paddingRight: '10px' }}><Paper style={{ backgroundColor: '#ffe50066', padding: '5px' }}>
                                <ListItem style={{ padding: '0px' }}>
                                    <ListItemIcon style={{ minWidth: '28px' }}><Assessment /></ListItemIcon>
                                    <ListItemText primary={<span style={{ fontSize: '13px' }}>Sales</span>} />
                                </ListItem><div>{mySellProfit.sellCount && mySellProfit.sellCount.sells.length > 0 && mySellProfit.sellCount.sells[0].amount}</div></Paper></Grid>
                            <Grid item xs={4} style={{ paddingRight: '10px' }}><Paper style={{ backgroundColor: '#e91e6336', padding: '5px' }}>
                                <ListItem style={{ padding: '0px' }}>
                                    <ListItemIcon style={{ minWidth: '28px' }}><Equalizer /></ListItemIcon>
                                    <ListItemText primary={<span style={{ fontSize: '13px' }}>Orders</span>} />
                                </ListItem><div>{mySellProfit.sellCount && mySellProfit.sellCount.orders}</div></Paper></Grid>
                            <Grid item xs={4}><Paper style={{ backgroundColor: '#03a9f429', padding: '5px' }}>
                                <ListItem style={{ padding: '0px' }}>
                                    <ListItemIcon style={{ minWidth: '28px' }}><TrendingUp /></ListItemIcon>
                                    <ListItemText primary={<span style={{ fontSize: '13px' }}>Profit</span>} />
                                </ListItem><div>{((mySellProfit.sellCount && mySellProfit.sellCount.sells.length > 0) ? mySellProfit.sellCount.sells[0].amount : 0) - ((mySellProfit.sellCount && mySellProfit.sellCount.profit.length > 0) ? mySellProfit.sellCount.profit[0].amount : 0)}</div></Paper></Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid >
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP PRODUCTS</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>PRODUCT NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px', textAlign: 'center' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px', textAlign: 'center' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {mySellProfit.mySellProdProfits.map((product) => {
                            return (
                                <Grid container item xs={12} direction="row">
                                    <Grid item xs={6}><Typography style={{ fontSize: '10px' }}><b>{product.productName}</b></Typography></Grid>
                                    <Grid item xs={3}><Typography style={{ fontSize: '11px', textAlign: 'center' }}><b>{product.sale}</b></Typography></Grid>
                                    <Grid item xs={3}><Typography style={{ fontSize: '11px', textAlign: 'center' }}><b>{product.sale - product.profit}</b></Typography></Grid>
                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP CATEGORIES</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>CATEGORY NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px', textAlign: 'center' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px', textAlign: 'center' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {mySellProfit.categorySells.map((category) => {
                            return (
                                <Grid container item xs={12} direction="row">
                                    <Grid item xs={6}><Typography style={{ fontSize: '10px' }}><b>{category.category}</b></Typography></Grid>
                                    <Grid item xs={3}><Typography style={{ fontSize: '11px', textAlign: 'center' }}><b>{category.sale}</b></Typography></Grid>
                                    <Grid item xs={3}><Typography style={{ fontSize: '11px', textAlign: 'center' }}><b>{category.sale - category.profit}</b></Typography></Grid>
                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP CUSTOMER</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>CUSTOMER NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px', textAlign: 'center' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px', textAlign: 'center' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {mySellProfit.custSellProducts.map((cust) => {
                            return (
                                <Grid container item xs={12} direction="row">
                                    <Grid item xs={6}><Typography style={{ fontSize: '10px' }}><b>{cust._id && cust._id.customerName}</b></Typography></Grid>
                                    <Grid item xs={3}><Typography style={{ fontSize: '11px', textAlign: 'center' }}><b>{cust.sale}</b></Typography></Grid>
                                    <Grid item xs={3}><Typography style={{ fontSize: '11px', textAlign: 'center' }}><b>{cust.sale - cust.profit}</b></Typography></Grid>
                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            {
                openDemandProduct && <Dialog open={openDemandProduct} onClose={handleClose} aria-labelledby="demand-product">
                    <DialogTitle id="demand-product" style={{ color: '#FF5722' }}>उत्पादनाची मागणी करा</DialogTitle>
                </Dialog>
            }
        </React.Fragment >

    );
}

export default MySells;