const monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'May', 'June', 'July',
    'Aug', 'Sep', 'Oct',
    'Nov', 'Dec',
];

export const getFullDate = (date) => {
    try {
        const date1 = new Date(date);
        const day = date1.getDate();
        const monthIndex = date1.getMonth();
        const year = date1.getFullYear();

        return `${day} ${monthNames[monthIndex]} ${year}`;
    } catch (err) {
        return date;
    }
};


export const getDate = (date) => {
    // var monthNames = [
    //     "Jan", "Feb", "Mar",
    //     "Apr", "May", "Jun", "Jul",
    //     "Aug", "Sept", "Oct",
    //     "Nov", "Dec"
    // ];

    let dateObj = new Date(date);

    var day = dateObj.getDate();
    var monthIndex = dateObj.getMonth();
    var year = dateObj.getFullYear();

    return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

export const getFormatedDateWithTime = (date) => {


    try {
        date = new Date(date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        const strTime = `${hours}:${minutes} ${ampm}`;
        // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}  ${strTime}`;
        return `${date.getDate()}-${monthNames[date.getMonth()]}-${date.getFullYear()}  ${strTime}`;
    } catch (err) {
        return date;
    }
};