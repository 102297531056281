import React, { useEffect } from "react";

import {
    CircularProgress, Dialog
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        overflow: 'hidden',
    }
}));

function Progress(props) {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Dialog open={props.open} style={{ margin: 'auto'}} classes={{ paper: classes.dialogPaper }}>
                <CircularProgress />
            </Dialog>
        </React.Fragment>
    )
}

export default Progress;