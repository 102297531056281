const crypto = require('crypto');
const algorithm = 'aes-256-cbc';
const key = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);

function encrypt(text) {

    try {
        let cipher = crypto.createCipher('aes-128-cbc', 'de@v^et$19');
        let encrypted = cipher.update(text, 'utf8', 'hex')
        encrypted += cipher.final('hex');
        return encrypted;
    }
    catch (err) {
        console.log(err);
    }

}

function decrypt(text) {
    try {
        var cipher = crypto.createDecipher('aes-128-cbc', 'de@v^et$19');
        var decrypted = cipher.update(text, 'hex', 'utf8')
        decrypted += cipher.final('utf8');
        return decrypted;
    }
    catch (err) {
        console.log(err);
    }
}

module.exports = { encrypt, decrypt };