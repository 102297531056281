import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import {
    DialogContent, AppBar, IconButton, Toolbar, Button, CardMedia, Card, DialogTitle,
    Typography, Grid, Dialog, Badge, Select, MenuItem, InputLabel, Fab, ListItemIcon, ListItem, ListItemText,
} from '@material-ui/core';

import {
    ArrowBackIos, ShoppingCartOutlined, CheckCircle, DeleteOutlined, AmpStories, Person, Phone
} from '@material-ui/icons';
import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
import { makeStyles, fade } from '@material-ui/core/styles';

const { api } = require('../configs/constants');

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        margin: '9px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    card: {
        border: '1px solid #80808080',
        boxShadow: 'none'
    },
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    fabButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px'
    },
    productName: {
        fontSize: 'medium',
        lineHeight: '22px',
        maxHeight: '44px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        webkitLineClamp: '2',
        display: '-webkit-box',
        webkitBoxOrient: 'vertical'
    },
    quantity: {
        fontSize: '12px',
        color: '#535353',
        marginTop: '5px',
        msTextOverflow: 'ellipsis',
        textOverflow: 'ellipsis'
    },
    smallText: {
        fontSize: '12px',
        color: '#535353',
        marginTop: '5px',
        msTextOverflow: 'ellipsis',
        textOverflow: 'ellipsis'
    },
    cover: {
        width: '72px',
        height: '72px',
        margin: '10px 10px 10px 10px'
    },
    media: {
        height: 160,
    },
    titleBar: {
        width: '25%',
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

function ViewCart() {
    const [quantity, setQuantity] = React.useState(0);
    const [confirm, setConfirm] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [newIndex, setIndex] = React.useState(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [productList, setProductList] = React.useState([]);
    const prodCount = JSON.parse(localStorage.getItem('productList'));
    const [price, setPrice] = React.useState(0);
    const [totalMrp, setTotalMrp] = React.useState(0);
    const [totalQuantity, setTotalQuantity] = React.useState(0);
    const [societyPrice, setSocietyPrice] = React.useState(0);
    const products = history.location.state.productList;
    var customerInfo = JSON.parse(localStorage.getItem('customerInfo'));

    useEffect(() => {
        productList && productList.length === 0 && setProductList(history.location.state.productList);
    })

    // Fetch store cust orders
    useEffect(function updateStatus() {
        let newSocprice = productList ? productList.reduce((a, b) => +a + +((b.productId ? b.productId.deavnetPriceForSociety : 0) * b.quantity), 0) : 0;
        let newprice = productList ? productList.reduce((a, b) => +a + +((b.productId ? b.productId.merchantPriceForFarmer : 0) * b.quantity), 0) : 0;
        let newtotalMrp = productList ? productList.reduce((a, b) => +a + +((b.productId ? b.productId.mrp : 0) * b.quantity), 0) : 0;
        let newquantity = productList ? productList.reduce((a, b) => +a + +b.quantity, 0) : 0;
        price != newprice && setPrice(newprice);
        totalMrp != newtotalMrp && setTotalMrp(newtotalMrp);
        totalQuantity != newquantity && setTotalQuantity(newquantity);
        societyPrice != newSocprice && setSocietyPrice(newSocprice);
        // setProductList(productList);
    });

    const goBack = () => {
        window.history.back();
    }

    const handleDialogClose = () => {
        setConfirm(false);
        history.push("/counter-orders");
    }

    const handleConfirmDialogClose = () => {
        setConfirmDialog(false)
    }

    const removeSelectedProduct = (id) => {
        const result = productList.filter(item => (item._id != id));
        setProductList(result);
        localStorage.setItem('productList', JSON.stringify(result));
        let state = { ...history.location.state };
        const newstate = state && state.productList.filter(item => (item._id != id));
        state.productList = newstate == undefined ? [] : newstate;
        history.replace({ ...history.location, state });
    }

    const handleChange = (e, index) => {
        if (productList[index].oldQty > e.target.value) {
            productList[index].quantity = e.target.value;
            setProductList(productList);
            newIndex == index && setIndex(null);
        } else {
            setIndex(index);
        }
    }

    const openConfirmDailog = () => {
        setConfirmDialog(true)
    }

    const saveOrders = () => {
        setProgress(true)
        let axios = getAxiosInstance();
        const user = authDetails();
        axios.post(api.SAVE_ORDER, {
            productList: productList,
            customerInfo: JSON.parse(localStorage.getItem('customerInfo')),
            userId: user.id,
            storeId: user.storeId,
            totalPrice: price,
            societyPrice: societyPrice,
            totalItems: totalQuantity,
        }).then(function (response) {
            setConfirmDialog(false);
            localStorage.removeItem('productList');
            localStorage.removeItem('customerInfo');
            setConfirm(true)
        }).catch(function (error) {
            setProgress(false)
        });
    }

    return (
        <React.Fragment>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}><b>Final List Price</b></Typography>
                    <IconButton aria-owns={true ? 'menu-appbar' : undefined}
                        aria-haspopup="true" color="inherit">
                        <Badge badgeContent={prodCount && prodCount.length} color="primary" classes={{ badge: classes.badge }}>
                            <ShoppingCartOutlined style={{ color: 'black' }} />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {productList && productList.length > 0 &&
                <Fab style={{ backgroundColor: '#FF5722', color: '#fff', zIndex: 1000 }} aria-label="add" className={classes.fabButton} onClick={openConfirmDailog} >
                    <AmpStories style={{ fontSize: '40px' }} />
                </Fab>
            }
            <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', padding: '10px' }}>
                <Grid container direction='row' justify='space-between'>
                    <Grid item style={{ color: '#000000e3' }} xs={8}>
                        <ListItem style={{ padding: '0px' }}>
                            <ListItemIcon style={{ minWidth: '28px' }}>
                                <Person />
                            </ListItemIcon>
                            <ListItemText primary={<span style={{ fontSize: '13px' }}>{customerInfo && customerInfo.customerName}</span>} />
                        </ListItem>
                    </Grid>
                    <Grid item style={{ color: '#000000e3' }} xs={4}>
                        <ListItem style={{ padding: '0px' }}>
                            <ListItemIcon style={{ minWidth: '28px' }}>
                                <Phone />
                            </ListItemIcon>
                            <ListItemText primary={<span style={{ fontSize: '13px' }}>{customerInfo && customerInfo.mobileNo}</span>} />
                        </ListItem>
                    </Grid>
                </Grid>
                {productList && productList.length > 0 &&
                    <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', padding: '10px 0px' }}>
                        <Card style={{ justifyContent: 'space-between', boxShadow: 'none', padding: '5px 10px', backgroundColor: '#00ffb33d' }}>
                            <Grid container direction='row' justify='space-between' style={{ paddingBottom: '7px' }}>
                                <Grid item xs={5}>
                                    <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>TOTAL ITEMS</b>  {totalQuantity}</span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>FINAL AMOUNT</b>₹{price}</span>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item xs={5}>
                                    <span style={{ fontSize: '13px' }}><b>Total MRP Amount</b></span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span style={{ fontSize: '13px' }}>₹{totalMrp}</span>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item xs={5}>
                                    <span style={{ fontSize: '13px' }}><b>Discount Amount</b></span>
                                </Grid>
                                <Grid item xs={7}>
                                    <span style={{ fontSize: '13px' }}>₹{price - totalMrp}</span>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                }
                <Grid item xs={12} sm={12} md={12}>
                    {productList && productList.length > 0 ?
                        (productList.map((cart, index) => {
                            return (
                                <Card style={{ marginBottom: '10px', backgroundColor: '#f1f5f8' }}>
                                    <Grid container item xs={12} direction='row'>
                                        <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                            <CardMedia style={{
                                                inlineSize: 'fit-content', margin: '10px',
                                                textAlign: 'center', height: '70px', width: '70px', backgroundColor: '#fff'
                                            }} image={cart.productId && cart.productId.productId && cart.productId.productId.images.length > 0 && cart.productId.productId.images[0]}
                                            />
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 10px 0px' }}>
                                            <Grid container>
                                                <Typography
                                                    style={{
                                                        fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                        overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                    }}>
                                                    {cart.productName}
                                                </Typography>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <span style={{ fontSize: '13px' }}>{cart.brandId && cart.brandId.name}</span>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'center' }}>
                                                    <span><span style={{ fontSize: '13px', paddingRight: '10px' }}>Stock</span><span style={{ fontSize: '13px' }}>{cart.oldQty} Bags</span></span>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ paddingTop: '10px' }}>
                                                {cart.productId &&
                                                    <span> <span style={{ fontSize: '13px', fontFamily: 'sans-serif', fontWeight: 800, marginRight: '10px' }}><b> {'₹' + cart.productId.merchantPriceForFarmer + '   '}</b></span>
                                                        <span style={{ textDecorationLine: 'line-through', fontSize: '13px', color: '#878787', marginRight: '10px' }}>
                                                            {'₹' + cart.productId.mrp}
                                                        </span>
                                                        <span style={{ color: 'black', fontSize: '13px' }} >
                                                            <b>{'  ' + cart.productId.discountRate + '% off'}</b>
                                                        </span></span>
                                                }
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={11} sm={11} md={11}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <InputLabel htmlFor="quantity" style={{ fontSize: '13px', color: (newIndex == index ? 'red' : 'black'), margin: '10px 10px 10px 0px' }}><b>Quantity:</b></InputLabel>
                                                        </Grid>
                                                        <Grid item>
                                                            <Select value={cart.quantity} style={{ color: newIndex == index ? 'red' : 'black' }}
                                                                onChange={(e) => handleChange(e, index)}
                                                                inputProps={{ name: 'quantity', id: 'quantity' }}
                                                                onClick={(e) => setQuantity(e.target.value)}>
                                                                <MenuItem value={1}>1</MenuItem>
                                                                <MenuItem value={2}>2</MenuItem>
                                                                <MenuItem value={3}>3</MenuItem>
                                                                <MenuItem value={4}>4</MenuItem>
                                                                <MenuItem value={5}>5</MenuItem>
                                                                <MenuItem value={6}>6</MenuItem>
                                                                <MenuItem value={7}>7</MenuItem>
                                                                <MenuItem value={8}>8</MenuItem>
                                                                <MenuItem value={12}>12</MenuItem>
                                                            </Select>
                                                            {newIndex == index &&
                                                                <span style={{ fontSize: '13px', color: 'red', margin: '10px 10px 10px 10px' }}><b>Qty: {cart.oldQty}</b></span>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'center' }}>
                                                    <DeleteOutlined style={{ color: 'red', marginTop: '5px' }}
                                                        onClick={(e) => removeSelectedProduct(cart._id)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            )
                        })
                        ) :
                        <div style={{ textAlign: 'center', paddingTop: '20px' }}><Typography>उत्पादने निवडली नाहीत</Typography></div>
                    }
                </Grid>
            </Grid>
            <Dialog onClose={handleConfirmDialogClose} open={confirmDialog} fullWidth={true} maxWidth='lg'>
                <Typography style={{ padding: '10px', fontSize: '13px' }}><b>ORDER # </b> str12345</Typography>
                <DialogContent style={{ padding: '0px' }}>
                    <Grid container direction='row' justify='space-between' style={{ margin: '10px' }}>
                        <Grid item style={{ color: '#000000e3' }} xs={7}>
                            <ListItem style={{ padding: '0px', lineHeight: 1 }}>
                                <ListItemIcon style={{ minWidth: '28px' }}>
                                    <Person />
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontSize: '13px' }}>{customerInfo && customerInfo.customerName}</span>} />
                            </ListItem>
                        </Grid>
                        <Grid item style={{ color: '#000000e3' }} xs={5}>
                            <ListItem style={{ padding: '0px' }}>
                                <ListItemIcon style={{ minWidth: '28px' }}>
                                    <Phone />
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontSize: '13px' }}>{customerInfo && customerInfo.mobileNo}</span>} />
                            </ListItem>
                        </Grid>
                    </Grid><hr style={{ backgroundColor: 'blue', margin: '10px', height: '1px' }} />
                    <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                        <Card style={{ justifyContent: 'space-between', margin: '8px', boxShadow: 'none', padding: '5px 10px', backgroundColor: '#00ffb33d' }}>
                            <Grid container direction='row' justify='space-between' style={{ paddingBottom: '7px' }}>
                                <Grid item xs={12}>
                                    <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>PRICE DETAILS ({totalQuantity} ITEMS)</b></span>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item xs={8}>
                                    <span style={{ fontSize: '13px' }}><b>Total MRP Amount</b></span>
                                </Grid>
                                <Grid item xs={4}>
                                    <span style={{ fontSize: '13px' }}>₹{totalMrp}</span>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item xs={8}>
                                    <span style={{ fontSize: '13px' }}><b>Discount Amount</b></span>
                                </Grid>
                                <Grid item xs={4}>
                                    <span style={{ fontSize: '13px' }}>₹{price - totalMrp}</span>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between' style={{ paddingTop: '15px' }}>
                                <Grid item xs={8}>
                                    <span style={{ fontSize: '13px', fontWeight: 900 }}>FINAL AMOUNT</span>
                                </Grid>
                                <Grid item xs={4}>
                                    <span style={{ fontSize: '13px', fontWeight: 900 }}>₹{price}</span>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                        <Button style={{ backgroundColor: '#FF5722', padding: '5px', margin: '30px 10px', fontSize: '11px', color: '#fff', width: '-webkit-fill-available' }}
                            disabled={progress} onClick={(e) => saveOrders()}>RECEVIED PAYMENT AND DELIVERD ORDER</Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog onClose={handleDialogClose} fullWidth={true} maxWidth='lg' open={confirm}>
                <DialogTitle style={{ padding: '15px 0px 0px', textAlign: 'center' }}><CheckCircle style={{ fontSize: '40px', color: 'green' }} /></DialogTitle>
                <div style={{ textAlign: 'center', color: 'green', fontSize: '14px', padding: '10px 30px 10px' }}><b>Thank you for the Shopping!</b></div>
            </Dialog>
        </React.Fragment>
    );
}

export default ViewCart;