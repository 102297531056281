import React from 'react';
import { hydrate, render } from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';

import { Provider } from 'react-redux';
import configureStore from './reducers/configStore';
import { history } from './helpers/history';

import * as serviceWorker from './serviceWorker';
import { Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-131502323-1');
const theme = createMuiTheme();

render(
    <MuiThemeProvider theme={theme}>
        <Provider store={configureStore()}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    </MuiThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
