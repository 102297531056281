import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    Paper, AppBar, Button, Toolbar, Typography, Grid, Card, CardContent, ListItem, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, ListItemText, ListItemIcon
} from '@material-ui/core';
import { ArrowBackIos, PersonPin, TrendingUp, Equalizer, Assessment, Home, Notifications, ShoppingBasket, Dehaze } from '@material-ui/icons';
import Progress from './progress';
import Snackbar from './snackbar';
import { getFullDate } from '../configs/date.config';
import { makeStyles } from '@material-ui/core/styles';
import { authDetails } from '../auth/authdetails';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', width: '97%', whiteSpace: 'nowrap', color: 'black', fontSize: '23px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));

function Shopping() {
    const [openDemandProduct, setOpenDemandProduct] = React.useState(false);
    const [bgColor, setbgColor] = React.useState(0);
    const [quantity, setQuantity] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const productDemand = useSelector(state => state.productDemand);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const user = authDetails();

    const renderComponent = (no) => {
        if (no == 1) {
            history.push("/");
        } else if (no == 2) {
            history.push("/notification");
        } else if (no == 4) {
            history.push("/settings");
        }
    }

    return (
        <React.Fragment>
            {productDemand.isInProgress && <Progress open={productDemand.isInProgress} />}
            {/* {<Snackbar message={productDemand.message} open={productDemand.message ? true : false} />} */}
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <Typography className={classes.title}>Statistics</Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP CATEGORIES</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>CATEGORY NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {productDemand.productDemands.map((product) => {
                            return (
                                <Grid item xs={12} sm={12} md={12}>

                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP PRODUCTS</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>PRODUCT NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {productDemand.productDemands.map((product) => {
                            return (
                                <Grid item xs={12} sm={12} md={12}>

                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px' }}>
                <Grid item xs={12}>
                    <Paper style={{ backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Typography style={{ paddingBottom: '10px', fontSize: '13px' }}><b>TOP CUSTOMER</b></Typography>
                        <Grid container item xs={12} direction="row">
                            <Grid item xs={6}><Typography style={{ fontSize: '12px' }}><b>CUSTOMER NAME</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>SALE</b></Typography></Grid>
                            <Grid item xs={3}><Typography style={{ fontSize: '12px' }}><b>PROFIT</b></Typography></Grid>
                        </Grid>
                        {productDemand.productDemands.map((product) => {
                            return (
                                <Grid item xs={12} sm={12} md={12}>

                                </Grid>
                            )
                        })}
                    </Paper>
                </Grid>
            </Grid><div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Home style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(1)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(2)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(4)} /></Button>
                </Grid>
            </Grid >
        </React.Fragment>

    );
}

export default Shopping;