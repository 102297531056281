import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_STORE_CUST_ORDERS_PROGRESS = "GET_STORE_CUST_ORDERS_PROGRESS";
const GET_STORE_CUST_ORDERS_SUCCESS = "GET_STORE_CUST_ORDERS_SUCCESS";
const GET_STORE_CUST_ORDERS_FAILURE = "GET_STORE_CUST_ORDERS_FAILURE";
const GET_STORE_COUNTER_ORDERS_PROGRESS = "GET_STORE_COUNTER_ORDERS_PROGRESS";
const GET_STORE_COUNTER_ORDERS_SUCCESS = "GET_STORE_COUNTER_ORDERS_SUCCESS";
const GET_STORE_COUNTER_ORDERS_FAILURE = "GET_STORE_COUNTER_ORDERS_FAILURE";

/**Online customer orders */
export const getStoreCustOrders = (flag, dispatch) => {
    dispatch({ type: GET_STORE_CUST_ORDERS_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_CUST_ORDERS, { params: { storeId: user.storeId, flag: flag } })
        .then(function (response) {
            dispatch({ type: GET_STORE_CUST_ORDERS_SUCCESS, custOrders: response.data });
        }).catch(function (error) {
            dispatch({ type: GET_STORE_CUST_ORDERS_FAILURE });
        });
}

/**Counter customer orders */
export const getStoreCounterOrders = (dispatch) => {
    dispatch({ type: GET_STORE_COUNTER_ORDERS_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_COUNTER_ORDERS, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_STORE_COUNTER_ORDERS_SUCCESS, storeCountOrderList: response.data.dataList, totalItems: response.data.totalItems });
        }).catch(function (error) {
            dispatch({ type: GET_STORE_COUNTER_ORDERS_FAILURE });
        });
}

// export const storeOrderReceive = (dispatch, order) => {
//     dispatch({ type: STORE_ORDER_RECEIVE_PROGRESS });

//     let axios = getAxiosInstance();

//     axios.put(apiConfig.api.STORE_ORDER_RECEIVE, { id: order._id })
//         .then(function (response) {
//             dispatch({ type: STORE_ORDER_RECEIVE_SUCCESS });
//         }).catch(function (error) {
//             dispatch({ type: STORE_ORDER_RECEIVE_FAILURE });
//         });
// }

let initialState = {
    custOrders: [],
    storeCountOrderList: [],
    totalItems: 0,
    isInProgress: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_STORE_CUST_ORDERS_PROGRESS:
            return { ...state };
        case GET_STORE_CUST_ORDERS_SUCCESS:
            return { ...state, custOrders: action.custOrders, totalItems: action.totalItems };
        case GET_STORE_CUST_ORDERS_FAILURE:
            return { ...state };
        case GET_STORE_COUNTER_ORDERS_PROGRESS:
            return { ...state, isInProgress: true };
        case GET_STORE_COUNTER_ORDERS_SUCCESS:
            return { ...state, storeCountOrderList: action.storeCountOrderList, totalItems: action.totalItems, isInProgress: false };
        case GET_STORE_COUNTER_ORDERS_FAILURE:
            return { ...state, isInProgress: false };
        default:
            return state;
    }
}

export default reducer;