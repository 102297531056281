import axios from 'axios';
//import { config } from './constants';
import { authDetails } from '../auth/authdetails';

export function getAxiosInstance() {
    // Set config defaults when creating the instance
    var instance = axios.create({
        // baseURL: 'http://localhost:4000/',
        baseURL: 'https://mapi.ekrushi.com/'
    });

    const user = authDetails();

    // Alter defaults after instance has been created
    instance.defaults.headers.common['Authorization'] = (user && user.token) ? user.token : null;

    return instance;
} 
