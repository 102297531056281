import { getAxiosInstance } from '../configs/axios.config';

const apiConfig = require('../configs/constants');

const GET_PROMOTIONAL_OFFERS_PROGRESS = "GET_PROMOTIONAL_OFFERS_PROGRESS";
const GET_PROMOTIONAL_OFFERS_SUCCESS = "GET_PROMOTIONAL_OFFERS_SUCCESS";
const GET_PROMOTIONAL_OFFERS_FAILURE = "GET_PROMOTIONAL_OFFERS_FAILURE";

export const getPromotionalOffer = (dispatch) => {
    dispatch({ type: GET_PROMOTIONAL_OFFERS_PROGRESS });

    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_PROMOTIONAL_OFFERS)
        .then(function (response) {
            dispatch({ type: GET_PROMOTIONAL_OFFERS_SUCCESS, promotionalOfferList: response.data.promotionalOffers });
        }).catch(function (error) {
            dispatch({ type: GET_PROMOTIONAL_OFFERS_FAILURE });
        });
}

let initialState = {
    promotionalOfferList: [],
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROMOTIONAL_OFFERS_PROGRESS:
            return { ...state };
        case GET_PROMOTIONAL_OFFERS_SUCCESS:
            return { ...state, promotionalOfferList: action.promotionalOfferList };
        case GET_PROMOTIONAL_OFFERS_FAILURE:
            return { ...state };
        default:
            return state;
    }
}
export default reducer;