import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPromotionalOffer } from '../reducers/promotional.offers.reducer';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Paper, MobileStepper, Button
} from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Link, useHistory } from 'react-router-dom';
import { appRoutes } from '../configs/constants';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
    a: {
        textDecoration: 'none',
    }
}));

function PromotionalOffer() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const promotionalOffers = useSelector(state => state.promotionalOffers);
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const maxSteps = promotionalOffers.promotionalOfferList.length;

    // Fetch store cust orders
    useEffect(() => { maxSteps == 0 && getPromotionalOffer(dispatch) }, []);

    const openOfferProduct = () => {
        history.push({ pathname: appRoutes.OFFER_PRODUCTS, offerList: promotionalOffers.promotionalOfferList });
    }

    const handleStepChange = (activeStep) => {
        setActiveStep({ activeStep });
    };

    return (
        <React.Fragment>
            <Paper style={{ border: '1px solid gray', boxShadow: 'unset' }}>
                {maxSteps > 0 && <React.Fragment>
                    <AutoPlaySwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep.activeStep} onChangeIndex={handleStepChange}
                        enableMouseEvents>
                        {promotionalOffers.promotionalOfferList.map((prodOffImg, index) => (
                            <React.Fragment>
                                {
                                    Math.abs(activeStep.activeStep - index) <= 2 ? (
                                        <div style={{ width: '100%', height: '176px' }} onClick={(e) => openOfferProduct()}>
                                            <img src={prodOffImg.image} style={{ width: '100%', height: '176px' }} />
                                        </div>
                                    ) : null
                                }
                            </React.Fragment>
                        ))}
                    </AutoPlaySwipeableViews>
                </React.Fragment>}
            </Paper>
        </React.Fragment>
    );
}

export default PromotionalOffer;