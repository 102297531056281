import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getStoreProdStocks, getNewStoreProd } from '../reducers/store.prod.stock.reducer'
import {
    CardMedia, ListItemIcon, Card, ListItem, ListItemText, Dialog, DialogContent, TextField, DialogTitle,
    Typography, Grid, Button, Fab, Badge, AppBar, Toolbar, IconButton,
} from '@material-ui/core';
import { Check, CheckCircle, AddShoppingCart, ArrowBackIos, Home, Notifications, ShoppingBasket, Dehaze, DeleteOutlined } from '@material-ui/icons';
import LoginImg from '../assets/loginImg.png'
import Progress from './progress';
import { makeStyles } from '@material-ui/core/styles';
import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');
const useStyles = makeStyles(theme => ({
    fabButton: {
        position: 'fixed',
        bottom: '50px',
        right: '20px'
    },
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));

function StoreProductStock() {
    const productStock = useSelector(state => state.productStock);
    const [openProdDialog, setOpenProdDialog] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openCartView, setOpenCartView] = React.useState(false);
    const [productInfo, setProductInfo] = React.useState('');
    const [quantity, setQuantity] = React.useState(1);
    const [progress, setProgress] = React.useState(false);
    const [seleIndex, setSeleIndex] = React.useState(null);
    const [remIndex, setRemIndex] = React.useState(null);
    const [price, setPrice] = React.useState(0);
    const [totalMrp, setTotalMrp] = React.useState(0);
    const [totalSocPrice, setTotalSocPrice] = React.useState(0);
    const [totalDevPrice, setTotalDevPrice] = React.useState(0);
    const [newStock, setNewStock] = React.useState(false);
    const [totalQuantity, setTotalQuantity] = React.useState(0);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [prodCount, setProdCount] = React.useState(JSON.parse(localStorage.getItem('demandProducts')) ? JSON.parse(localStorage.getItem('demandProducts')) : []);

    // Fetch store cust orders
    useEffect(() => {
        let data = { stock: history.location.state && history.location.state.stock ? history.location.state.stock : 0 }
        if (history.location.state && history.location.state.stock === 3) {
            getNewStoreProd(dispatch);
            setNewStock(true);
        } else {
            getStoreProdStocks(data, dispatch);
            setNewStock(false);
        }
    }, []);

    // update value
    useEffect(function updateStatus() {
        if (prodCount.length > 0) {
            if (openCartView) {
                let newFarPrice = prodCount.reduce((a, b) => +a + +(b.merchantPriceForFarmer * b.quantity), 0);
                let newSocPrice = prodCount.reduce((a, b) => +a + +(b.deavnetPriceForSociety * b.quantity), 0);
                let newDevPrice = prodCount.reduce((a, b) => +a + +(b.deavnetPrice * b.quantity), 0);
                let newtotalMrp = prodCount.reduce((a, b) => +a + +(b.mrp * b.quantity), 0);
                let newquantity = prodCount.reduce((a, b) => +a + +b.quantity, 0);
                price != newFarPrice && setPrice(newFarPrice);
                totalMrp != newtotalMrp && setTotalMrp(newtotalMrp);
                totalSocPrice != newSocPrice && setTotalSocPrice(newSocPrice);
                totalDevPrice != newDevPrice && setTotalDevPrice(newDevPrice);
                totalQuantity != newquantity && setTotalQuantity(newquantity);
            } else {
                let newFarPrice = prodCount.reduce((a, b) => +a + +(b.merchantPriceForFarmer * b.quantity), 0);
                let newSocPrice = prodCount.reduce((a, b) => +a + +(b.deavnetPriceForSociety * b.quantity), 0);
                let newDevPrice = prodCount.reduce((a, b) => +a + +(b.deavnetPrice * b.quantity), 0);
                let newtotalMrp = prodCount.reduce((a, b) => +a + +(b.mrp * b.quantity), 0);
                let newquantity = prodCount.reduce((a, b) => +a + +b.quantity, 0);
                price != newFarPrice && setPrice(newFarPrice);
                totalMrp != newtotalMrp && setTotalMrp(newtotalMrp);
                totalSocPrice != newSocPrice && setTotalSocPrice(newSocPrice);
                totalDevPrice != newDevPrice && setTotalDevPrice(newDevPrice);
                totalQuantity != newquantity && setTotalQuantity(newquantity);
            }
        } else {
            setPrice(0);
            setTotalMrp(0);
            setTotalQuantity(0);
            setTotalDevPrice(0);
            setTotalSocPrice(0);
        }
    });

    const goBack = () => {
        window.history.back();
    }

    const openProductDialog = (productInfo, index) => {
        setOpenProdDialog(true);
        setProductInfo(productInfo);
        setSeleIndex(index)
        setRemIndex(index)
    }

    const openProdCartView = () => {
        setOpenCartView(true);
    }

    const handelClose = () => {
        setOpenProdDialog(false);
        setOpenCartView(false)
        setProductInfo('');
        setOpenSuccess(false);
        setSeleIndex(null)
    }

    const handelChangeComponent = (e) => {
        history.push('/');
    }

    const saveOrders = () => {
        setProgress(true);
        const user = authDetails();
        let axios = getAxiosInstance();
        let date = Date.now();
        if (openCartView == false) {
            var products = [{
                productName: newStock ? productInfo.productId.productName : productInfo.productName,
                quantity: parseInt(quantity),
                brandId: newStock ? (productInfo.productId.brandId && productInfo.productId.brandId._id) : (productInfo.brandId && productInfo.brandId._id),
                merchantId: newStock ? (productInfo.productId.merchantId && productInfo.productId.merchantId.merchantId) : (productInfo.merchantId && productInfo.merchantId.merchantId),
                categoryId: newStock ? (productInfo.productId && productInfo.productId.categoryId) : productInfo.categoryId,
                brand: newStock ? (productInfo.productId.brandId && productInfo.productId.brandId.name) : (productInfo.brandId && productInfo.brandId.name),
                merchant: newStock ? (productInfo.productId.merchantId && productInfo.productId.merchantId.storeName) : (productInfo.merchantId && productInfo.merchantId.storeName),
                productId: newStock ? (productInfo.productId._id) : (productInfo.productId.productId._id),
                productImg: newStock ? (productInfo.productId.images[0]) : (productInfo.productId.productId.images[0]),
                mrp: newStock ? (productInfo.mrp) : (productInfo.productId.mrp),
                deavnetPrice: newStock ? (productInfo.deavnetPrice) : (productInfo.productId.deavnetPrice),
                merchantPriceForFarmer: newStock ? (productInfo.merchantPriceForFarmer) : (productInfo.productId.merchantPriceForFarmer),
                deavnetPriceForSociety: newStock ? (productInfo.deavnetPriceForSociety) : (productInfo.productId.deavnetPriceForSociety),
                discount: newStock ? (productInfo.discountRate) : (productInfo.productId.discountRate),
            }]
        }
        let data = {
            products: openCartView ? prodCount : products,
            quantity: openCartView ? totalQuantity : parseInt(quantity),
            totalMrp: openCartView ? totalMrp : (newStock ? productInfo.mrp : productInfo.productId.mrp) * parseInt(quantity),
            totalFarPrice: openCartView ? price : (newStock ? productInfo.merchantPriceForFarmer : productInfo.productId.merchantPriceForFarmer) * parseInt(quantity),
            totalSocPrice: openCartView ? totalSocPrice : (newStock ? productInfo.deavnetPriceForSociety : productInfo.productId.deavnetPriceForSociety) * parseInt(quantity),
            totalDevPrice: openCartView ? totalDevPrice : (newStock ? productInfo.deavnetPrice : productInfo.productId.deavnetPrice) * parseInt(quantity),
            orderId: (user.storeId + (openCartView ? totalQuantity : quantity) + date),
            userId: user.id,
            storeId: user.storeId,
        }
        axios.post(apiConfig.api.DEMAND_PRODUCT, data)
            .then(function (response) {
                setOpenProdDialog(false);
                setOpenSuccess(true);
                setProgress(false);
                localStorage.removeItem('demandProducts');
            }).catch(function (error) {
                setProgress(false);
            });
    };

    /**Add to cart multi product */
    const addToCart = () => {
        let products = {
            productName: newStock ? productInfo.productId.productName : productInfo.productName,
            brand: newStock ? (productInfo.productId.brandId && productInfo.productId.brandId.name) : (productInfo.brandId && productInfo.brandId.name),
            merchant: newStock ? (productInfo.productId.merchantId && productInfo.productId.merchantId.storeName) : (productInfo.merchantId && productInfo.merchantId.storeName),
            quantity: parseInt(quantity),
            brandId: newStock ? (productInfo.productId.brandId && productInfo.productId.brandId._id) : (productInfo.brandId && productInfo.brandId._id),
            merchantId: newStock ? (productInfo.productId.merchantId && productInfo.productId.merchantId.merchantId) : (productInfo.merchantId && productInfo.merchantId.merchantId),
            categoryId: newStock ? (productInfo.productId && productInfo.productId.categoryId) : (productInfo.categoryId),
            productId: newStock ? (productInfo.productId._id) : (productInfo.productId.productId._id),
            productImg: newStock ? (productInfo.productId.images[0]) : (productInfo.productId.productId.images[0]),
            mrp: newStock ? (productInfo.mrp) : (productInfo.productId.mrp),
            deavnetPrice: newStock ? (productInfo.deavnetPrice) : (productInfo.productId.deavnetPrice),
            merchantPriceForFarmer: newStock ? (productInfo.merchantPriceForFarmer) : (productInfo.productId.merchantPriceForFarmer),
            deavnetPriceForSociety: newStock ? (productInfo.deavnetPriceForSociety) : (productInfo.productId.deavnetPriceForSociety),
            discount: newStock ? (productInfo.discountRate) : (productInfo.productId.discountRate),
        }
        var a = [];
        a = JSON.parse(localStorage.getItem('demandProducts')) || [];
        a.push(products);
        localStorage.setItem('demandProducts', JSON.stringify(a));
        setRemIndex(null);
        setOpenProdDialog(false);
        setProdCount(a);
    }

    const removeSelectedProduct = (proId, index) => {
        var a = [];
        a = JSON.parse(localStorage.getItem('demandProducts')) || [];
        const result = a.filter(item => (item.productId != (newStock ? (proId.productId._id) : (proId.productId && proId.productId.productId._id))));
        localStorage.setItem('demandProducts', JSON.stringify(result));
        setRemIndex(index);
        setSeleIndex(null);
        setProdCount(result);
    }

    const removeSeleCartProduct = (id) => {
        const result = prodCount.filter(item => (item.productId != id));
        localStorage.setItem('demandProducts', JSON.stringify(result));
        setProdCount(result);
    }

    return (
        <React.Fragment>
            <Fab style={{ backgroundColor: '#FF5722', color: '#fff', zIndex: 1000 }} aria-label="add" className={classes.fabButton} >
                <Badge badgeContent={prodCount && prodCount.length} color="primary">
                    <AddShoppingCart style={{ fontSize: '40px' }} onClick={(e) => openProdCartView()} />
                </Badge>
            </Fab>
            {productStock.isInProgress && <Progress open={productStock.isInProgress} />}
            {newStock ?
                <Grid container item xs={12} sm={12} md={12} style={{ margin: '0px' }} direction="row">
                    {productStock.productStocks.map((product, index) => {
                        const result = prodCount && prodCount.filter(item => (item.productId == (product.productId && product.productId._id)));
                        return (
                            <Card style={{ marginBottom: '10px' }}>
                                <Grid container item xs={12} direction='row'>
                                    <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                        <CardMedia style={{
                                            inlineSize: 'fit-content', margin: '10px 10px 0px',
                                            textAlign: 'center', height: '50px', width: '70px', backgroundColor: '#f1f5f8'
                                        }}
                                            image={(product.productId && product.productId.images) ? product.productId.images[0] : LoginImg}
                                        />
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 10px 0px' }}>
                                        <Grid container>
                                            <Typography
                                                style={{
                                                    fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                    overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                }}>
                                                {product.productId && product.productId.productName}
                                            </Typography>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <span><span style={{ fontSize: '13px', paddingRight: '10px' }}>{'₹' + product.merchantPriceForFarmer}</span><span style={{ paddingRight: '10px', fontSize: '12px', textDecorationLine: 'line-through' }}>{'₹' + product.mrp}</span><span style={{ fontSize: '14px', color: 'green' }}>{'₹' + product.deavnetPriceForSociety}</span></span>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                                                {result.length > 0 ?
                                                    <Button style={{ fontSize: '11px', color: 'red', padding: '3px 15px' }} onClick={(e) => removeSelectedProduct(product, index)}><b>REMOVE</b></Button> :
                                                    <Button variant="contained" style={{ fontSize: '11px', padding: '3px 15px' }} color='primary' onClick={(e) => openProductDialog(product, index)} ><b>Purches</b></Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ padding: '0px 10px' }}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px', width: '97%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57 }}><b>Brand: </b>{product.productId && product.productId.brandId && product.productId.brandId.name}</span>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px', width: '97%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57 }}><b>Supplier: </b>{product.productId && product.productId.merchantId && product.productId.merchantId.storeName}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        )
                    })}
                </Grid> :
                <Grid container item xs={12} sm={12} md={12} style={{ margin: '0px' }} direction="row">
                    {productStock.productStocks.map((product, index) => {
                        const result = prodCount && prodCount.filter(item => (item.productId == (product.productId && product.productId.productId && product.productId.productId._id)));
                        return (
                            <Card style={{ marginBottom: '10px' }}>
                                <Grid container item xs={12} direction='row'>
                                    <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                        <CardMedia style={{
                                            inlineSize: 'fit-content', margin: '10px 10px 0px',
                                            textAlign: 'center', height: '50px', width: '70px', backgroundColor: '#f1f5f8'
                                        }}
                                            image={(product.productId && product.productId.productId && product.productId.productId.images) ? product.productId.productId.images[0] : LoginImg}
                                        />
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 10px 0px' }}>
                                        <Grid container>
                                            <Typography
                                                style={{
                                                    fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                    overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                }}>
                                                {product.productName}
                                            </Typography>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <span><span style={{ fontSize: '13px', paddingRight: '10px' }}>Stock</span><span style={{ fontSize: '13px' }}>{product.quantity} Bags</span></span>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'right' }}>
                                                {result.length > 0 ?
                                                    <Button style={{ fontSize: '11px', color: 'red', padding: '3px 15px' }} onClick={(e) => removeSelectedProduct(product, index)}><b>REMOVE</b></Button> :
                                                    <Button variant="contained" style={{ fontSize: '11px', padding: '3px 15px' }} color='primary' onClick={(e) => openProductDialog(product, index)} ><b>Purches</b></Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ padding: '0px 10px' }}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px', width: '97%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57 }}><b>Brand: </b>{product.brandId && product.brandId.name}</span>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px', width: '97%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57 }}><b>Supplier: </b>{product.merchantId && product.merchantId.storeName}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        )
                    })}
                </Grid>
            }
            {openProdDialog &&
                <Dialog open={openProdDialog} fullWidth={true} maxWidth='lg'>
                    <Grid item style={{ color: '#000000e3' }} xs={12}>
                        <ListItem style={{ padding: '0px 10px' }}>
                            <ListItemIcon style={{ minWidth: '28px', paddingRight: '10px' }}>
                                <Check style={{ fontSize: '35px', color: 'blue' }} />
                            </ListItemIcon>
                            <ListItemText primary={<span style={{ fontSize: '13px' }}><b>Product add to your shopping cart & select new</b></span>} />
                        </ListItem>
                    </Grid>
                    <hr style={{ backgroundColor: 'blue', height: '1px', color: 'blue', margin: '2px 8px' }} />
                    <DialogContent style={{ padding: '0px' }}>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                            <Card style={{ marginBottom: '10px', boxShadow: 'unset' }}>
                                <Grid container item xs={12} direction='row'>
                                    <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                        <CardMedia style={{
                                            inlineSize: 'fit-content', margin: '10px 10px',
                                            textAlign: 'center', height: '50px', width: '70px', backgroundColor: '#f1f5f8'
                                        }} image={newStock ? (productInfo.productId && productInfo.productId.images.length > 0 && productInfo.productId.images[0]) : (productInfo.productId && productInfo.productId.productId && productInfo.productId.productId.images.length > 0) ? productInfo.productId.productId.images[0] : LoginImg}
                                        />
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 10px 0px' }}>
                                        <Grid container>
                                            <Typography
                                                style={{
                                                    fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                    overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                }}>
                                                {newStock ? (productInfo.productId && productInfo.productId.productName) : productInfo.productName}
                                            </Typography>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={9} sm={9} md={9} style={{ textAlign: 'right', paddingTop: '5px' }}>
                                                <span style={{ fontSize: '12px', paddingRight: '12px' }}><b>Quantity: </b></span>
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'right' }}>
                                                <TextField type="number" name="quantity" value={quantity} size="small" onChange={(e) => setQuantity(e.target.value)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ padding: '0px 10px' }}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px' }}><b>Brand: </b>{newStock ? (productInfo.productId.brandId && productInfo.productId.brandId.name) : (productInfo.brandId && productInfo.brandId.name)}</span>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px', paddingRight: '10px' }}><b>Supplier: </b>{newStock ? (productInfo.productId.merchantId && productInfo.productId.merchantId.storeName) : (productInfo.merchantId && productInfo.merchantId.storeName)}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                            <Card style={{ justifyContent: 'space-between', margin: '8px', boxShadow: 'none', padding: '5px 10px', backgroundColor: '#00ffb33d' }}>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={12}>
                                        <span style={{ fontSize: '13px' }}><b>PRICE DETAILS TOTAL ({quantity} ITEMS)</b></span>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Total MRP Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>₹{(newStock ? (productInfo.mrp) : (productInfo.productId.mrp)) * quantity}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Discount Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>-₹{(newStock ? (productInfo.mrp - productInfo.merchantPriceForFarmer) : (productInfo.productId.mrp - productInfo.productId.merchantPriceForFarmer)) * quantity}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Total Order Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>₹{(newStock ? (productInfo.merchantPriceForFarmer) : (productInfo.productId.merchantPriceForFarmer)) * quantity}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Delivery Charges</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>FREE</span>
                                    </Grid>
                                </Grid>
                                <hr style={{ margin: '7px 0px' }} />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px', fontWeight: 900 }}>Total AMOUNT</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px', fontWeight: 900 }}>₹{(newStock ? (productInfo.merchantPriceForFarmer) : (productInfo.productId.merchantPriceForFarmer)) * quantity}</span>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <Button style={{ backgroundColor: '#3F51B5', padding: '5px 0px', margin: '0px 5px 0px 10px', fontSize: '11px', color: '#fff', width: '-webkit-fill-available' }}
                                        onClick={(e) => addToCart(productInfo)}>CONTINUE SHOPPING</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button style={{ backgroundColor: '#bfbfbf', padding: '5px', margin: '0px 10px 0px 5px', fontSize: '11px', color: 'black', width: '-webkit-fill-available' }}
                                        onClick={handelClose}>CANCEL ORDER</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button style={{ backgroundColor: '#FF5722', padding: '5px', margin: '10px 10px', fontSize: '11px', color: '#fff', width: '-webkit-fill-available' }}
                                disabled={progress} onClick={(e) => saveOrders()}>CONFIRM ORDER</Button>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            {openCartView &&
                <Dialog open={openCartView} fullScreen fullWidth={true}>
                    <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                        <Toolbar>
                            <IconButton edge="start" aria-label="menu" onClick={handelClose}>
                                <ArrowBackIos style={{ color: 'black' }} />
                            </IconButton>
                            <Typography className={classes.title}><b>My Cart</b></Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container>
                        <Grid item style={{ color: '#000000e3' }}>
                            <ListItem style={{ padding: '0px 10px' }}>
                                <ListItemIcon style={{ minWidth: '28px', paddingRight: '10px' }}>
                                    <Check style={{ fontSize: '35px', color: 'blue' }} />
                                </ListItemIcon>
                                <ListItemText primary={<span style={{ fontSize: '13px' }}><b>Product successfully added to your shopping cart</b></span>} />
                            </ListItem>
                        </Grid>
                    </Grid>
                    <hr style={{ backgroundColor: 'blue', height: '1px', color: 'blue', margin: '2px 8px' }} />
                    <DialogContent style={{ padding: '0px', backgroundColor: '#f1f5f8' }}>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                            <Card style={{ marginBottom: '10px', boxShadow: 'unset', backgroundColor: '#f1f5f8' }}>
                                {prodCount.map(function (product, index) {
                                    return (
                                        <Grid container item xs={12} direction='row'>
                                            <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                                <CardMedia style={{
                                                    inlineSize: 'fit-content', margin: '10px 10px',
                                                    textAlign: 'center', height: '50px', width: '70px', backgroundColor: '#fff'
                                                }}
                                                    image={product.productImg}
                                                />
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 10px 0px' }}>
                                                <Grid container>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                            overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                        }}>
                                                        {product.productName}
                                                    </Typography>
                                                </Grid>
                                                <Grid container>
                                                    <Grid item xs={8} sm={8} md={8} style={{ textAlign: 'right', paddingTop: '5px' }}>
                                                        <span style={{ fontSize: '12px', paddingRight: '12px' }}><b>Quantity: </b></span>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'right' }}>
                                                        <TextField type="number" name="quantity" value={product.quantity} size="small" />
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} style={{ textAlign: 'center' }}>
                                                        <DeleteOutlined style={{ color: 'red', marginTop: '5px' }}
                                                            onClick={(e) => removeSeleCartProduct(product.productId)} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{ padding: '0px 10px 3px', borderBottom: '1px solid #fff' }}>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <span style={{ fontSize: '12px' }}><b>Brand: </b>{product.brand}</span>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <span style={{ fontSize: '12px', paddingRight: '10px' }}><b>Supplier: </b>{product.merchant}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                            <Card style={{ justifyContent: 'space-between', margin: '8px', boxShadow: 'none', padding: '5px 10px', backgroundColor: '#00ffb33d' }}>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={12}>
                                        <span style={{ fontSize: '13px' }}><b>PRICE DETAILS TOTAL ({totalQuantity} ITEMS)</b></span>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Total MRP Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>₹{totalMrp}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Discount Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>-₹{totalMrp - price}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Total Order Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>₹{price}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Delivery Charges</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>FREE</span>
                                    </Grid>
                                </Grid>
                                <hr style={{ margin: '7px 0px' }} />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px', fontWeight: 900 }}>TOTAL AMOUNT</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px', fontWeight: 900 }}>₹{price}</span>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center', position: 'fixed', bottom: 0, marginBottom: '35px', width: '-webkit-fill-available' }}>
                            {prodCount.length > 0 ?
                                <Button style={{ backgroundColor: '#FF5722', padding: '5px', margin: '10px 10px', fontSize: '11px', color: '#fff', width: '-webkit-fill-available' }}
                                    disabled={progress} onClick={(e) => saveOrders()}>CONFIRM ORDER</Button> :
                                <Button style={{ backgroundColor: '#80808033', padding: '5px', margin: '10px 10px', fontSize: '11px', color: '#fff', width: '-webkit-fill-available' }}
                                >CONFIRM ORDER</Button>
                            }
                        </Grid>
                    </DialogContent>
                    <div style={{ marginBottom: '35px' }}></div>
                    <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                    </Grid>
                </Dialog>
            }
            <Dialog onClose={handelChangeComponent} fullWidth={true} maxWidth='lg' open={openSuccess}>
                <DialogTitle style={{ padding: '15px 0px 0px', textAlign: 'center' }}><CheckCircle style={{ fontSize: '40px', color: 'green' }} /></DialogTitle>
                <div style={{ textAlign: 'center', color: 'green', fontSize: '14px' }}><b>Thank you for the Shopping!</b></div>
                <div style={{ textAlign: 'center', color: 'black', fontSize: '12px', paddingBottom: '20px' }}>Purchase order will be delivered on your registered addressed.</div>
            </Dialog>
        </React.Fragment>
    );
}

export default StoreProductStock;