import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_OFFER_PROD_LIST_PROGRESS = "GET_OFFER_PROD_LIST_PROGRESS";
const GET_OFFER_PROD_LIST_SUCCESS = "GET_OFFER_PROD_LIST_SUCCESS";
const GET_OFFER_PROD_LIST_FAILURE = "GET_OFFER_PROD_LIST_FAILURE";

export const getOfferProducts = (dispatch) => {
    dispatch({ type: GET_OFFER_PROD_LIST_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_OFFER_PROD_LIST, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_OFFER_PROD_LIST_SUCCESS, offerList: response.data.dataList, totalItems: response.data.totalItems });
        }).catch(function (error) {
            dispatch({ type: GET_OFFER_PROD_LIST_FAILURE });
        });
}

let initialState = {
    offerList: [],
    isInProgress: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_OFFER_PROD_LIST_PROGRESS:
            return { ...state };
        case GET_OFFER_PROD_LIST_SUCCESS:
            return { ...state, offerList: action.offerList };
        case GET_OFFER_PROD_LIST_FAILURE:
            return { ...state };
        default:
            return state;
    }
}

export default reducer;