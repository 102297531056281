import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getOfferProducts } from '../reducers/offer.products.reducer';
import {
    Avatar, AppBar, IconButton, Toolbar,
    Typography, Grid, Card, CardContent, CardMedia, CardHeader,
    Fab, Dialog, DialogContent, Button,
} from '@material-ui/core';
import {
    ArrowBackIos, ShoppingCart, MoreHoriz, Notifications, Home, ShoppingBasket, Dehaze
} from '@material-ui/icons';
import Progress from './progress';
import { getFullDate } from '../configs/date.config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    fabButton: {
        position: 'fixed',
        bottom: '50px',
        right: '20px'
    },
    productName: {
        fontSize: 'medium',
        lineHeight: '22px',
        maxHeight: '44px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        webkitLineClamp: '2',
        display: '-webkit-box',
        webkitBoxOrient: 'vertical'
    },
    quantity: {
        fontSize: '12px',
        color: '#535353',
        marginTop: '5px',
        msTextOverflow: 'ellipsis',
        textOverflow: 'ellipsis'
    },
    smallText: {
        fontSize: '12px',
        color: '#535353',
        marginTop: '5px',
        msTextOverflow: 'ellipsis',
        textOverflow: 'ellipsis'
    },
    cover: {
        width: '72px',
        height: '72px',
        margin: '10px 10px 10px 10px'
    },
    avatar: {
        color: 'black',
        backgroundColor: '#fff',
        borderRadius: 'unset',
        minWidth: '130px',
        textAlign: 'left'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));

function OfferProducts() {
    const [openOfferProduct, setOpenOfferProduct] = React.useState(false);
    const [prouctDetail, setProuctDetail] = React.useState();
    const offerProducts = useSelector(state => state.offerProducts);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const productList = history.location.offerList ? history.location.offerList : [];

    // // Fetch product offers list
    // useEffect(() => { }, []);

    const goBack = () => {
        window.history.back();
    }

    const handleClickOpenOrder = (info) => {
        setOpenOfferProduct(true);
        setProuctDetail(info)
    }

    const handleClose = () => {
        setOpenOfferProduct(false);
    }

    return (
        <React.Fragment>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}><b>Offers</b></Typography>
                </Toolbar>
            </AppBar>
            <Fab style={{ backgroundColor: '#FF5722', color: '#fff' }} aria-label="add" className={classes.fabButton} >
                <ShoppingCart style={{ fontSize: '30px' }} />
            </Fab>
            <Grid container direction="row" style={{ padding: '7px 7px 0px', backgroundColor: '#f1f5f8' }}>
                {productList.map((offer, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={12}>
                            <Card style={{ padding: '8px', marginBottom: '8px' }}>
                                <CardHeader style={{ padding: '0px 0px 8px' }}
                                    avatar={<Avatar aria-label="recipe" className={classes.avatar}>Agrovet</Avatar>}
                                    action={<IconButton aria-label="settings"><MoreHoriz /></IconButton>}
                                    subheader={<div style={{ textAlign: 'right', fontSize: '10px', color: 'black', paddingBottom: '8px' }}>Sponsored</div>}
                                />
                                <CardMedia style={{ height: '100px' }}
                                    onClick={(e) => handleClickOpenOrder(offer)} image={offer.image} />
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
            </Grid>
            {
                openOfferProduct && <Dialog open={openOfferProduct} onClose={handleClose} aria-labelledby="order-info" fullScreen >
                    <DialogContent style={{ padding: '0px', backgroundColor: '#f1f5f8' }}>
                        <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                            <Toolbar>
                                <IconButton edge="start" className={classes.menuButton}
                                    aria-label="menu" onClick={handleClose}>
                                    <ArrowBackIos style={{ color: 'black' }} />
                                </IconButton>
                                <Typography className={classes.title}><b>Offers Details</b></Typography>
                            </Toolbar>
                        </AppBar>
                        <Fab style={{ backgroundColor: '#FF5722', color: '#fff' }} aria-label="add" className={classes.fabButton} >
                            <ShoppingCart style={{ fontSize: '30px' }} />
                        </Fab>
                        <Grid item xs={12} sm={12} md={12} style={{ width: '-webkit-fill-available' }}>
                            <Card style={{ padding: '8px', margin: '7px' }}>
                                <CardHeader style={{ padding: '0px 0px 8px' }}
                                    avatar={<Avatar aria-label="recipe" className={classes.avatar}>Agrovet</Avatar>}
                                    action={<IconButton aria-label="settings"><MoreHoriz /></IconButton>}
                                    subheader={<div style={{ textAlign: 'right', fontSize: '10px', color: 'black', paddingBottom: '8px' }}>Sponsored</div>}
                                />
                                <CardMedia style={{ height: '100px' }} image={prouctDetail.image} />
                                <CardContent style={{ padding: '10px' }}>
                                    <Typography style={{ fontSize: '14px', color: 'black' }}><b>Details</b></Typography>
                                    <Typography style={{ color: 'black', paddingTop: '10px', textAlign: 'justify', fontSize: '14px', lineHeight: '15px' }}>
                                        This impressive paella is a perfect party dish and a fun meal to cook together with your
                                        guests. Add 1 cup of frozen peas along with the mussels, if you like.
        </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </DialogContent>
                    <div style={{ marginBottom: '35px' }}></div>
                    <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                    </Grid>
                </Dialog>
            }
        </React.Fragment>
    );
}

export default OfferProducts;