import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    Paper, AppBar, IconButton, Toolbar, Typography, Grid, Button, CardMedia, ListItem, Dialog, Avatar, DialogContent,
    DialogContentText, DialogTitle, ListItemText, ListItemIcon, DialogActions, InputAdornment
} from '@material-ui/core';
import { ArrowBackIos, LocalOffer, PowerSettingsNew, Security, Help, Storefront, VisibilityOff, Visibility, ContactMail, Apartment, Room, PhoneIphone, Alarm, MailOutline, LocationCity, FiberPin, PostAdd, Person, Home, Notifications, ShoppingBasket, Dehaze, TrainRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
import { constants } from '../configs/constants';
import storeImg from '../assets/storeImg.png';
const { api } = require('../configs/constants');
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', width: '97%', whiteSpace: 'nowrap', color: 'black', fontSize: '23px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));

function Settings() {
    const [passDialog, setOpenPassDialog] = React.useState(false);
    const [bgColor, setbgColor] = React.useState(0);
    const [pass, setPass] = React.useState('');
    const [showPass, setShowPass] = React.useState(false);
    const [showConPass, setShowConPass] = React.useState(false);
    const [confPass, setConfPass] = React.useState('');
    const [storeInfoDialog, setOpenStoreInfo] = React.useState(false);
    const [helpDialog, setHelpDialog] = React.useState(false);
    const [openTerms, setOpenTermsCond] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const productDemand = useSelector(state => state.productDemand);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const user = authDetails();
    const storeInfo = JSON.parse(localStorage.getItem('storeInfo'));

    const openPassDialog = (open) => {
        setOpenPassDialog(open);
    };

    const openHelp = (open) => {
        setHelpDialog(open);
    };

    const openTermsCond = (open) => {
        setOpenTermsCond(open)
    }

    const logout = () => {
        localStorage.removeItem(constants.STORE_USER);
        history.push("/login");
    }

    const openStoreInfo = (open) => {
        setOpenStoreInfo(open);
    }

    const handleClickShowPassword = (show) => {
        setShowPass(show);
    };

    const handleClickShowConfPass = (show) => {
        setShowConPass(show);
    };

    const savePassword = () => {
        if (pass !== confPass) {
            alert("Password are not Same!");
        } else {
            setProgress(true)
            let axios = getAxiosInstance();
            const user = authDetails();
            axios.post(api.CHANGE_PASSWORD, {
                id: user.id,
                password: pass,
            }).then(function (response) {
                setOpenPassDialog(false);
                setProgress(false)
            }).catch(function (error) {
                setProgress(false)
            });
        }
    }

    const renderComponent = (no) => {
        if (no == 1) {
            history.push("/");
        } else if (no == 3) {
            history.push("/shopping");
        } else if (no == 2) {
            history.push("/notification");
        }
    }

    return (
        <React.Fragment>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <Typography className={classes.title}>Store Management</Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="row" style={{ paddingTop: '10px', backgroundColor: '#f1f5f8' }}>
                <Grid container style={{ backgroundColor: '#cbcbcb', margin: '0px 10px', borderRadius: '10px' }} item xs={12}>
                    <Paper>
                        <Grid container item xs={12}>
                            <Grid item xs={3}>
                                {user.img ? <CardMedia style={{
                                    inlineSize: 'fit-content', margin: '10px 10px 0px',
                                    textAlign: 'center', height: '60px', width: '70px', backgroundColor: '#fff'
                                }} image={<Person />}
                                /> : <div style={{ margin: '10px 10px 0px', backgroundColor: '#f1f5f8' }}><Person style={{ fontSize: '60px' }} /></div>
                                }
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container style={{ padding: '10px' }}>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: '16px' }}><b>{user.name}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: '13px' }}>{user.designation}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{ fontSize: '13px' }}>{user.mobileNo}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <hr style={{ margin: '10px', width: '100%' }} />
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <LocalOffer />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Update Customer Price and Stock</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <PostAdd />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Add Own Products</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <ContactMail />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Staff Access Control</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }} onClick={(e) => openStoreInfo(true)}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <Storefront />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Store Information</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }} onClick={(e) => openHelp(true)}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <Help />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Help & Support</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }} onClick={(e) => openTermsCond(true)}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <Help />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Terms & conditions</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }} onClick={(e) => openPassDialog(true)}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <Security />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Change Password</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper style={{ padding: '3px', margin: '0px 10px 10px', borderRadius: '10px', border: '1px solid #2196F3', backgroundColor: '#dcf0ff', boxShadow: 'unset' }}>
                                    <ListItem style={{ padding: '0px' }} onClick={(e) => logout()}>
                                        <ListItemIcon style={{ padding: '0px 8px', minWidth: '28px' }}>
                                            <PowerSettingsNew />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>Logout</span>} />
                                    </ListItem>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Home style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(1)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(2)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(3)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
            </Grid >
            {storeInfoDialog && <Dialog open={storeInfoDialog} fullScreen >
                <DialogContent style={{ padding: '0px' }}>
                    <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                        <Toolbar>
                            <IconButton edge="start" aria-label="menu" onClick={(e) => openStoreInfo(false)}>
                                <ArrowBackIos style={{ color: 'black' }} />
                            </IconButton>
                            <Typography className={classes.title}>View Store Information</Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Paper style={{ padding: '30px 10px' }}>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <CardMedia style={{
                                        inlineSize: 'fit-content', margin: '10px 10px 0px', marginLeft: '38%', marginBottom: '30px',
                                        height: '60px', width: '70px', backgroundColor: '#fff'
                                    }} image={storeImg} />
                                </Grid>
                                <Grid item style={{ marginBottom: '10px' }} xs={12}>
                                    <ListItem style={{ padding: '10px', border: '1px solid #2196F3', borderRadius: '10px', background: '#0876ff45' }}>
                                        <ListItemIcon style={{ minWidth: '28px', paddingRight: '8px' }}>
                                            <Storefront />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>{storeInfo.name}</span>} />
                                    </ListItem>
                                </Grid>
                                <Grid item style={{ marginBottom: '10px' }} xs={12}>
                                    <ListItem style={{ padding: '10px', border: '1px solid #2196F3', borderRadius: '10px', background: '#0876ff45' }}>
                                        <ListItemIcon style={{ minWidth: '28px', paddingRight: '8px' }}>
                                            <Room />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>{storeInfo.address}</span>} />
                                    </ListItem>
                                </Grid>
                                <Grid item style={{ marginBottom: '10px' }} xs={12}>
                                    <ListItem style={{ padding: '10px', border: '1px solid #2196F3', borderRadius: '10px', background: '#0876ff45' }}>
                                        <ListItemIcon style={{ minWidth: '28px', paddingRight: '8px' }}>
                                            <FiberPin />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>{storeInfo.pincode}</span>} />
                                    </ListItem>
                                </Grid>
                                <Grid item style={{ marginBottom: '10px' }} xs={12}>
                                    <ListItem style={{ padding: '10px', border: '1px solid #2196F3', borderRadius: '10px', background: '#0876ff45' }}>
                                        <ListItemIcon style={{ minWidth: '28px', paddingRight: '8px' }}>
                                            <Apartment />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>{storeInfo.taluka}</span>} />
                                    </ListItem>
                                </Grid>
                                <Grid item style={{ marginBottom: '10px' }} xs={12}>
                                    <ListItem style={{ padding: '10px', border: '1px solid #2196F3', borderRadius: '10px', background: '#0876ff45' }}>
                                        <ListItemIcon style={{ minWidth: '28px', paddingRight: '8px' }}>
                                            <LocationCity />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '16px' }}>{storeInfo.district}</span>} />
                                    </ListItem>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </DialogContent>
                <div style={{ marginBottom: '35px' }}></div>
                <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px' }}><Home style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(1)} /></Button>
                    </Grid>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(2)} /></Button>
                    </Grid>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(3)} /></Button>
                    </Grid>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                    </Grid>
                </Grid>
            </Dialog>
            }
            {helpDialog && <Dialog open={helpDialog} fullScreen >
                <DialogContent style={{ padding: '0px' }}>
                    <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                        <Toolbar>
                            <IconButton edge="start" aria-label="menu" onClick={(e) => openHelp(false)}>
                                <ArrowBackIos style={{ color: 'black' }} />
                            </IconButton>
                            <Typography className={classes.title}>Help & Support</Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', backgroundColor: '#f1f5f8', padding: '10px' }}>
                        <Paper>
                            <Grid container item xs={12}>
                                <Grid container direction='row' justify='space-between' style={{ padding: '10px' }}>
                                    <Grid item xs={12}>
                                        <Typography style={{ textAlign: 'center' }}><b>Contact Us!</b></Typography>
                                        <hr />
                                    </Grid>
                                    <Grid item style={{ color: '#000000e3', padding: '10px' }} xs={12}>
                                        <Avatar style={{ margin: '0% 42%', backgroundColor: '#0000008a', marginBottom: '10px' }}><Room /></Avatar>
                                        <Typography style={{ textAlign: 'center', fontSize: '13px', color: '#02020299' }}><b>Reg. Office - Rutumbhara-Pradnya, Siddhivinayk Hoc, Soc-Saswad, Pune 412301</b></Typography>
                                        <hr />
                                    </Grid>
                                    <Grid item style={{ color: '#000000e3', padding: '10px' }} xs={12}>
                                        <Avatar style={{ margin: '0% 42%', backgroundColor: '#0000008a', marginBottom: '10px' }}><PhoneIphone /></Avatar>
                                        <Typography style={{ textAlign: 'center', fontSize: '13px', color: '#02020299' }}><b>+91 9370467763</b></Typography>
                                        <Typography style={{ textAlign: 'center', fontSize: '13px', color: '#02020299' }}><b>+91 9284915800</b></Typography>
                                        <hr />
                                    </Grid>
                                    <Grid item style={{ color: '#000000e3', padding: '10px' }} xs={12}>
                                        <Avatar style={{ margin: '0% 42%', backgroundColor: '#0000008a', marginBottom: '10px' }}><MailOutline /></Avatar>
                                        <Typography style={{ textAlign: 'center', fontSize: '13px', color: '#02020299' }}><b>E-mail: deavnetsolution@gmail.com</b></Typography>
                                        <hr />
                                    </Grid>
                                    <Grid item style={{ color: '#000000e3', padding: '10px' }} xs={12}>
                                        <Avatar style={{ margin: '0% 42%', backgroundColor: '#0000008a', marginBottom: '10px' }}><Alarm /></Avatar>
                                        <Typography style={{ textAlign: 'center', fontSize: '13px', color: '#02020299' }}><b>Time: 24 hours</b></Typography>
                                        <hr />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                    </Grid>
                </DialogContent>
                <div style={{ marginBottom: '35px' }}></div>
                <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px' }}><Home style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(1)} /></Button>
                    </Grid>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(2)} /></Button>
                    </Grid>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(3)} /></Button>
                    </Grid>
                    <Grid item style={{ width: '25%', textAlign: 'center' }}>
                        <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                    </Grid>
                </Grid>
            </Dialog>
            }
            {
                passDialog && <Dialog open={passDialog}>
                    <DialogTitle style={{ textAlign: 'center', paddingTop: '30px' }}>Change Password</DialogTitle>
                    <ValidatorForm onSubmit={savePassword}>
                        <DialogContent style={{ padding: '20px 15px 50px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextValidator id="pass" name="pass" type={showPass ? 'text' : 'password'}
                                        data-minlength="4" maxLength="10" value={pass} onChange={(e) => setPass(e.target.value)}
                                        label="नवीन पासवर्ड" validators={['required']} errorMessages={['Please Insert New Password']} autoComplete='off'
                                        margin="dense" variant="outlined" fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="Toggle password visibility" >
                                                        {showPass ? <Visibility onClick={(e) => handleClickShowPassword(false)} /> : <VisibilityOff onClick={(e) => handleClickShowPassword(true)} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator id="confPass" name="confPass" type={showConPass ? 'text' : 'password'}
                                        data-match="#inputPassword" data-match-error="Whoops, these don't match" maxLength="10" value={confPass}
                                        onChange={(e) => setConfPass(e.target.value)} label="Confirm Password" validators={['required']} errorMessages={['Please check Confirm Password']}
                                        margin="dense" variant="outlined" fullWidth autoComplete='off'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="Toggle password visibility" >
                                                        {showConPass ? <Visibility onClick={(e) => handleClickShowConfPass(false)} /> : <VisibilityOff onClick={(e) => handleClickShowConfPass(true)} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={2}>
                                <Grid item xs={6} style={{ paddingRight: '4px' }}>
                                    <Button disabled={progress} type='submit' variant="contained" style={{ fontSize: '11px', padding: '4px 0px', width: '100%', backgroundColor: '#1f1fff', borderRadius: '0px', border: '1px solid blue', color: '#fff' }}>SAVE</Button>
                                </Grid>
                                <Grid item xs={6} style={{ paddingLeft: '4px' }}>
                                    <Button variant="contained" onClick={(e) => openPassDialog(false)} style={{ fontSize: '11px', padding: '4px', width: '100%', backgroundColor: '#1f1fff54', borderRadius: '0px', border: '1px solid blue', color: 'black' }}>CANCEL</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            }
            {openTerms &&
                <Dialog open={openTerms} aria-labelledby="order-info" fullScreen >
                    <DialogContent style={{ padding: '0px' }}>
                        <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                            <Toolbar>
                                <IconButton edge="start" aria-label="menu" onClick={(e) => openTermsCond()}>
                                    <ArrowBackIos style={{ color: 'black' }} />
                                </IconButton>
                                <Typography style={{ flexGrow: 1, color: 'black', fontSize: '23px' }}><b>Terms & Conditions</b></Typography>
                            </Toolbar>
                        </AppBar>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                            <div style={{ textAlign: 'justify', padding: '0px 10px', fontFamily: 'system-ui' }}><span><span>Terms & Conditions:</span><br />
                        Please read the following terms and conditions carefully as it sets out the terms of a legally binding agreement between you (the reader) and Business Standard Private Limited.
                        <br />
                        1) Introduction:<br />
                        This following sets out the terms and conditions on which you may use the content on
                        business-standard.com website, business-standard.com's mobile browser site, Business Standard instore Applications and other digital publishing services (www.smartinvestor.in, www.bshindi.com and www.bsmotoring,com) owned by Business Standard Private Limited, all the services herein will be referred to as Business Standard Content Services.
<br />
                        2) Registration Access and Use: <br />
                        We welcome users to register on our digital platforms. We offer the below mentioned registration services which may be subject to change in the future. All changes will be appended in the terms and conditions page and communicated to existing users by email.
                        <br />
                        Registration services are offered for individual subscribers only. If multiple individuals propose to access the same account or for corporate accounts kindly contact or write in to us. Subscription rates will vary for multiple same time access.

                        The nature and volume of Business Standard content services you consume on the digital platforms will vary according to the type of registration you choose, on the geography you reside or the offer you subscribe to.

                        a) Free Registration
                        <br />
                        b) Premium Registration
                        <br />
                        c) Special Offers
                        <br />
                        d) Combo registrations with partners
                        <br />
                        The details of the services and access offered for each account have been listed on
                        www.business-standard.com/subscription-cart/product
                        <br />
                        We may in exceptional circumstances cease to provide subscription services. We will give you at least 7 days notice of this, if possible. If we do so, then we will have no further obligation to you.
                        <br />
                        3) Privacy Policy and Registration: <br />
                        All information received by us from your registration on business-standard.com or other digital products of Business Standard will be used by Business Standard in accordance with our Privacy Policy. Kindly read the below mentioned details.
                        <br />
                        On registration, we expect you to provide Business Standard with an accurate and complete information of the compulsory fields. We also expect you to keep the information secure, specifically access passwords and payment information. Kindly update the information periodically to keep your account relevant. Business Standard will rely on any information you provide to us.

                        Each registration is for a single user only. On registration, you will choose a user name and password ("ID"). You are not allowed to share your ID or give access to your account to anyone else. Business Standard Premium subscription does not allow multiple users on a network or within an organization to use the same ID.

                        On knowledge, Business Standard may cancel or suspend your access to Business Standard premium services if it comes across you sharing your personal access without further obligation to you.

                        You are responsible for all the use of business-standard.com premium service made by you or anyone else using your ID and for preventing unauthorised use of your ID. If you believe there has been any breach of security such as the disclosure, theft or unauthorised use of your ID or any payment information, you must notify Business Standard immediately by e-mailing us at assist@bsmail.in. We recommend that you do not select an obvious user password (such as your name) and that you change it regularly.

                        If you provide Business Standard with an email address that will result in any messages Business Standard may send you being sent to you via a network or device operated or owned by a third party (e.g. your employer or college) then you promise that you are entitled to receive those messages. To ensure email's land in your inbox, you will add the bsmail receipt id to your safe list. You also agree that Business Standard may stop sending messages to you without notifying you.
                        <br />
                        Important - Kindly note
                        <br />
                        (A) When you participate and choose to subscribe to joint content subscription offers that Business Standard partners with; your email id, access password and entered personal information will be shared with the participating content partner brand via an encrypted server to server protocol. This sharing is to facilitate your seamless access across the partner brand's platform. Once you login to the partner brand's platform, specific terms and privacy policies of the partner brand (mentioned on its website) will apply.
                        <br />
                        (B) Joint offers including special price offers are generally limited to new users on the partner sites. Clubbed Offers on partner sites will not be available to you should your email id be registered with the partner website.

                        You are advised to study the offer before you subscribe.
</span>
                            </div>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
        </React.Fragment>

    );
}

export default Settings;