import React from "react";
import { Route } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Home from './components/home';
import MySell from './components/my.sell';
import CounterOrders from './components/counter.orders';
import StoreOrder from './components/store.order';
import CustOrder from './components/cust.order';
import Stats from './components/stats';
import ProductOrders from './components/product.orders';
import MyCounter from './components/my.counter';
import ViewProduct from './components/view.product';
import Cart from './components/cart';
import Login from './components/login';
import UserSetting from './components/user.setting';
import Shopping from './components/shopping';
import Notification from './components/notification';
import OfferProducts from './components/offer.products';
import { PrivateRoute } from './components/private.route';

import { appRoutes } from './configs/constants';

function App() {
  const app = useSelector(state => state);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <PrivateRoute exact path={appRoutes.HOME} component={Home}></PrivateRoute>
      <Route path={appRoutes.LOGIN} component={Login}></Route>
      <PrivateRoute path={appRoutes.MY_SELL} component={MySell}></PrivateRoute>
      <PrivateRoute path={appRoutes.STORE_ORDERS} component={StoreOrder}></PrivateRoute>
      <PrivateRoute path={appRoutes.CUST_ONLINE_ORDERS} component={CustOrder}></PrivateRoute>
      <PrivateRoute path={appRoutes.COUNTER_ORDERS} component={CounterOrders}></PrivateRoute>
      <PrivateRoute path={appRoutes.STATS} component={Stats}></PrivateRoute>
      <PrivateRoute path={appRoutes.PRODUCT_ORDERS} component={ProductOrders}></PrivateRoute>
      <PrivateRoute path={appRoutes.VIEW_PRODUCT} component={ViewProduct}></PrivateRoute>
      <PrivateRoute path={appRoutes.MY_COUNTER} component={MyCounter}></PrivateRoute>
      <PrivateRoute path={appRoutes.CART} component={Cart}></PrivateRoute>
      <PrivateRoute path={appRoutes.OFFER_PRODUCTS} component={OfferProducts}></PrivateRoute>
      <PrivateRoute path={appRoutes.SETTINGS} component={UserSetting}></PrivateRoute>
      <PrivateRoute path={appRoutes.SHOPPING} component={Shopping}></PrivateRoute>
      <PrivateRoute path={appRoutes.NOTIFICATION} component={Notification}></PrivateRoute>
    </React.Fragment>
  );
}

export default App;