import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import {
    AppBar, IconButton, Toolbar, CardActionArea, CardContent, Card,
    Typography, Grid, MobileStepper, Button,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { ArrowBackIos, KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    card: {
        border: '1px solid #80808080',
        boxShadow: 'none'
    },
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
}));

function ViewProduct() {
    const [activeStep, setActiveStep] = React.useState(0);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const maxSteps = (history.location.state.productInfo.productId && history.location.state.productInfo.productId.productId) ?
        history.location.state.productInfo.productId.productId.images.length : 0;
    const product = history.location.state.productInfo;

    // Fetch store cust orders
    useEffect(function updateStatus() {

    });

    const goBack = () => {
        window.history.back();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <React.Fragment>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}>View Product Details</Typography>
                    {/* <IconButton aria-owns={true ? 'menu-appbar' : undefined}
                        aria-haspopup="true" color="inherit">
                        <Badge badgeContent={prodCount && prodCount.length} color="primary" classes={{ badge: classes.badge }}>
                            <ShoppingCartOutlined onClick={gotoCart} />
                        </Badge>
                    </IconButton> */}
                </Toolbar>
            </AppBar >
            <Grid container item xs={12} style={{ margin: '0px' }}>
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <SwipeableViews index={activeStep}>
                                {product.productId && product.productId.productId && product.productId.productId.images.map((imgPath, index) => (
                                    <div key={index} style={{ textAlign: 'center' }}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                            <img style={{ height: '300px' }} src={imgPath} alt={product.productName} />
                                        ) : null}
                                    </div>
                                ))}
                            </SwipeableViews>
                            <MobileStepper steps={maxSteps} position="static" activeStep={activeStep} style={{ width: '-webkit-fill-available' }}
                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                        Next
                                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                            Back
                                </Button>
                                } />
                            <CardContent style={{ padding: '12px' }}>
                                <Typography style={{
                                    fontWeight: 500, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                    overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '16px', marginBottom: 'inherit'
                                }}>{product.productName}</Typography>
                                <Typography style={{
                                    width: '95%', whiteSpace: 'nowrap',
                                    overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                }}>{product.productId && product.productId.productId && product.productId.productId.englishTitle}</Typography>
                                <Typography>
                                    <span style={{ fontSize: '14px', fontFamily: 'sans-serif', fontWeight: 800, marginRight: '10px' }}>{'₹' + (product.productId && product.productId.deavnetPrice) + '   '}</span>
                                    <span style={{ textDecorationLine: 'line-through', fontSize: '13px', color: '#878787', marginRight: '10px' }}>
                                        {'₹' + (product.productId && product.productId.mrp)}
                                    </span>
                                    <span style={{ color: '#FF5722', fontSize: '13px' }}><b>
                                        {'  (' + (product.productId && product.productId.discountRate) + '% off)'}</b>
                                    </span></Typography>
                                <Typography style={{fontSize: '13px'}}>Brand: {product.brand}</Typography>
                                <Typography style={{fontSize: '13px'}}>Supplier: {product.merchant}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.card} style={{ padding: '0px 12px', marginTop: '6px' }}>
                        <p style={{ fontSize: '14px' }}>Product Details: </p>
                        <p style={{ fontSize: '13px' }}>
                            <span dangerouslySetInnerHTML={{ __html: (product.productId && product.productId.productId && product.productId.productId.description) }} /></p>
                        <p style={{ fontSize: '14px' }}>Features: </p>
                        <p style={{ fontSize: '13px', color: '#878787' }}>
                            <span dangerouslySetInnerHTML={{ __html: (product.productId && product.productId.productId && product.productId.productId.features) }} />
                        </p>
                        <p style={{ fontSize: '14px' }}>How to Use: </p>
                        <p style={{ fontSize: '13px', color: '#878787' }}>
                            <span dangerouslySetInnerHTML={{ __html: (product.productId && product.productId.productId && product.productId.productId.howToUse) }} />
                        </p>
                        <p style={{ fontSize: '14px' }}>Summary: </p>
                        <p style={{ fontSize: '13px', color: '#878787' }}>
                            <span dangerouslySetInnerHTML={{ __html: (product.productId && product.productId.productId && product.productId.productId.summary) }} />
                        </p>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ViewProduct;