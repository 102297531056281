import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { getProductList } from '../reducers/product.orders.reducer';
import {
    Paper, AppBar, IconButton, Toolbar, Button, CardMedia, Fab, Card, DialogTitle, ListItemIcon, ListItem,
    Typography, Grid, Dialog, Badge, DialogContent, InputBase, TextField, MenuItem, Divider, ListItemText
} from '@material-ui/core';
import {
    ArrowBackIos, ShoppingCartOutlined, Search, LibraryAddCheck, Check, CheckCircle, Home, Notifications, ShoppingBasket, Dehaze, Person, Phone, DeleteOutlined
} from '@material-ui/icons';
import { appRoutes } from '../configs/constants';
import Progress from './progress';
import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
import { makeStyles, fade } from '@material-ui/core/styles';
const { api } = require('../configs/constants');
const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    titleBar: {
        width: '25%',
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    fabButton: {
        position: 'fixed',
        bottom: '50px',
        right: '20px'
    },
    icon: {
        color: 'white',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    listbox: {
        width: 200,
        margin: 0,
        padding: 0,
        color: '#282c3f',
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#4a8df6',
            color: '#282c3f',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: '#282c3f',
        },
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
    root: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

function ProductOrders() {
    const productOrders = useSelector(state => state.productOrders);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [confirm, setConfirm] = React.useState(false);
    const [productInfo, setProductInfo] = React.useState(null);
    const [progress, setProgress] = React.useState(false);
    const [openProdDialog, setOpenProdDialog] = React.useState(false);
    const [quantity, setQuantity] = React.useState(1);
    const [seleIndex, setSeleIndex] = React.useState(null);
    const [remIndex, setRemIndex] = React.useState(null);
    const [products, setProducts] = React.useState([]);
    var prodCount = JSON.parse(localStorage.getItem('productList')) ? JSON.parse(localStorage.getItem('productList')) : [];
    var customerInfo = JSON.parse(localStorage.getItem('customerInfo'));
    // const {
    //     getRootProps,
    //     getInputLabelProps,
    //     getInputProps,
    //     getListboxProps,
    //     getOptionProps,
    //     groupedOptions,
    // } = useAutocomplete({
    //     id: 'use-autocomplete-demo',
    //     options: productOrders.productList,
    //     getOptionLabel: (option) => option.productName,
    // });

    useEffect(() => {
        getProductList(dispatch)
    }, [])

    useEffect(function updateStatus() {
        productOrders.productList.length === 0 && productOrders.isInProgress == false && getProductList(dispatch);
        products.length === 0 ? setProducts(productOrders.productList) : setProducts(products);
        prodCount = JSON.parse(localStorage.getItem('productList'));
    });

    const goBack = () => {
        window.history.back();
    }

    const viewProduct = (productInfo) => {
        history.push({
            pathname: appRoutes.VIEW_PRODUCT,
            state: { productInfo: productInfo }
        });
    }

    const openProductDialog = (productInfo) => {
        setOpenProdDialog(true);
        setProductInfo(productInfo);
    }

    const openCart = () => {
        history.push({ pathname: appRoutes.CART, state: { productList: prodCount } });
    }

    const addProductToCart = (product, index) => {
        product.oldQty = product.quantity;
        product.quantity = 1;
        let newProduct = product;
        var a = [];
        a = JSON.parse(localStorage.getItem('productList')) || [];
        a.push(newProduct);
        localStorage.setItem('productList', JSON.stringify(a));
        products[index].deleted = true;
        products[index].quantity = product.oldQty;
        setProducts(products);
        setSeleIndex(index)
        setRemIndex(null)
    }

    const removeSelectedProduct = (proId, index) => {
        products[index].deleted = false;
        var a = [];
        a = JSON.parse(localStorage.getItem('productList')) || [];
        const result = a.filter(item => (item._id != proId));
        setProducts(products);
        localStorage.setItem('productList', JSON.stringify(result));
        setRemIndex(index)
        setSeleIndex(null)
    }

    const handleDialogClose = () => {
        setConfirm(false);
        history.push("/counter-orders");
    }

    const saveOrders = () => {
        setProgress(true)
        let productList = [];
        productInfo.quantity = quantity;
        productList.push(productInfo);
        let axios = getAxiosInstance();
        const user = authDetails();
        axios.post(api.SAVE_ORDER, {
            productList: productList,
            customerInfo: customerInfo,
            userId: user.id,
            storeId: user.storeId,
            totalPrice: (productInfo.productId.merchantPriceForFarmer) * quantity,
            societyPrice: (productInfo.productId.deavnetPriceForSociety) * quantity,
            totalItems: quantity,
        }).then(function (response) {
            localStorage.removeItem('customerInfo');
            setConfirm(true)
        }).catch(function (error) {
            setProgress(false)
        });
    }

    return (
        <React.Fragment>
            {productOrders.isInProgress && <Progress open={productOrders.isInProgress} />}
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}><b>List</b></Typography>
                    {/* <div className={classes.search} {...getRootProps()}>
                        <div className={classes.searchIcon}><Search /></div>
                        <InputBase placeholder="Search…" {...getInputProps()} 
                            classes={{ root: classes.inputRoot, input: classes.inputInput }}
                        />
                        {groupedOptions.length > 0 ? (
                            <ul className={classes.listbox} {...getListboxProps()}>
                                {groupedOptions.map((option, index) => (
                                    <li {...getOptionProps({ option, index })} style={{ color: '#282c3f', padding: '2px' }} onClick={(e) => setProduct(option.productName)}>{option.productName}</li>
                                ))}
                            </ul>
                        ) : null}
                    </div> */}
                    <IconButton aria-owns={true ? 'menu-appbar' : undefined} aria-haspopup="true" color="inherit" onClick={openCart}>
                        <Badge badgeContent={prodCount && prodCount.length} color="primary">
                            <ShoppingCartOutlined style={{ color: 'black' }} />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Fab style={{ backgroundColor: '#FF5722', color: '#fff', zIndex: 1000 }} aria-label="add" className={classes.fabButton} onClick={openCart}>
                <LibraryAddCheck style={{ fontSize: '40px' }} />
            </Fab>
            <Grid container direction='row' justify='space-between'>
                <Grid item xs={12} container style={{ width: '-webkit-fill-available', margin: '12px' }}>
                    <Grid item style={{ color: '#000000e3' }} xs={8}>
                        <ListItem style={{ padding: '0px' }}>
                            <ListItemIcon style={{ minWidth: '28px' }}>
                                <Person />
                            </ListItemIcon>
                            <ListItemText primary={<span style={{ fontSize: '13px' }}>{customerInfo && customerInfo.customerName}</span>} />
                        </ListItem>
                    </Grid>
                    <Grid item style={{ color: '#000000e3' }} xs={4}>
                        <ListItem style={{ padding: '0px' }}>
                            <ListItemIcon style={{ minWidth: '28px' }}>
                                <Phone />
                            </ListItemIcon>
                            <ListItemText primary={<span style={{ fontSize: '13px' }}>{customerInfo && customerInfo.mobileNo}</span>} />
                        </ListItem>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='space-between'>
                    <Grid item xs={12} container style={{ width: '-webkit-fill-available', margin: '0px 10px 5px' }}>
                        <Paper component="form" className={classes.root} style={{ backgroundColor: '#f1f5f8' }}>
                            <InputBase
                                className={classes.input}
                                placeholder="Search Product..."
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <Divider className={classes.divider} orientation="vertical" />
                            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                                <Search />
                            </IconButton>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container item xs={12} direction='row' justify='space-between' style={{ padding: '10px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                        {products.map((product, index) => {
                            const result = prodCount && prodCount.filter(item => (item._id == product._id));
                            return (
                                <Card style={{ marginBottom: '10px', backgroundColor: '#f1f5f8' }}>
                                    <Grid container item xs={12} direction='row'>
                                        <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                            <CardMedia style={{
                                                inlineSize: 'fit-content', margin: '10px',
                                                textAlign: 'center', height: '70px', width: '70px', backgroundColor: '#fff'
                                            }}
                                                image={product.productId && product.productId.productId && product.productId.productId.images.length > 0 && product.productId.productId.images[0]}
                                            />
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 10px 0px' }}>
                                            <Grid container onClick={(e) => viewProduct(product)}>
                                                <Typography
                                                    style={{
                                                        fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                        overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                    }}>
                                                    {product.productName}
                                                </Typography>
                                            </Grid>
                                            <Grid container onClick={(e) => viewProduct(product)}>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <span style={{ fontSize: '13px' }}>{product.brandId && product.brandId.name}</span>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'center' }}>
                                                    <span><span style={{ fontSize: '13px', paddingRight: '10px' }}>Stock</span><span style={{ fontSize: '13px' }}>{product.quantity} Bags</span></span>
                                                </Grid>
                                            </Grid>
                                            <Grid container onClick={(e) => viewProduct(product)}>
                                                {product.productId &&
                                                    <span style={{ paddingTop: '5px' }}> <span style={{ fontSize: '13px', fontFamily: 'sans-serif', fontWeight: 800, marginRight: '10px' }}><b> {'₹' + product.productId.merchantPriceForFarmer + '   '}</b></span>
                                                        <span style={{ textDecorationLine: 'line-through', fontSize: '13px', color: '#878787', marginRight: '10px' }}>
                                                            {'₹' + product.productId.mrp}
                                                        </span>
                                                        <span style={{ color: 'black', fontSize: '13px' }} >
                                                            <b>{'  ' + product.productId.discountRate + '% off'}</b>
                                                        </span></span>
                                                }
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={6} sm={6} md={6} style={{ textAlign: 'center' }}>
                                                    {product.deleted || result.length > 0 ?
                                                        <Button style={{ fontSize: '11px', color: 'red', padding: '6px 0px' }} onClick={(e) => removeSelectedProduct(product._id, index)}><b>REMOVE FROM CART</b></Button> :
                                                        <Button style={{ fontSize: '12px', color: '#ff5722' }} onClick={(e) => addProductToCart(product, index)}><b>ADD TO CART</b></Button>
                                                    }
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1}><Divider className={classes.divider} orientation="vertical" /></Grid>
                                                <Grid item xs={5} sm={5} md={5} style={{ textAlign: 'center' }}>
                                                    <Button style={{ fontSize: '12px', color: '#ff5722' }} onClick={(e) => openProductDialog(product)}><b>BUY NOW</b></Button>
                                                    {/* <DeleteOutlined style={{ color: 'red', marginTop: '5px' }}
                                                        onClick={(e) => removeSelectedProduct(product._id)} /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
            </Grid>
            {openProdDialog &&
                <Dialog open={openProdDialog} fullWidth={true} maxWidth='lg'>
                    <Grid item style={{ color: '#000000e3' }} xs={12}>
                        <ListItem style={{ padding: '0px 10px' }}>
                            <ListItemIcon style={{ minWidth: '28px', paddingRight: '10px' }}>
                                <Check style={{ fontSize: '35px', color: 'blue' }} />
                            </ListItemIcon>
                            <ListItemText primary={<span style={{ fontSize: '13px' }}><b>Thank you! for Selecting your Product</b></span>} />
                        </ListItem>
                    </Grid>
                    <hr style={{ backgroundColor: 'blue', height: '1px', color: 'blue', margin: '2px 8px' }} />
                    <DialogContent style={{ padding: '0px' }}>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                            <Card style={{ marginBottom: '10px', boxShadow: 'unset' }}>
                                <Grid container item xs={12} direction='row'>
                                    <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                        <CardMedia style={{
                                            inlineSize: 'fit-content', margin: '10px', textAlign: 'center', height: '70px', width: '70px', backgroundColor: '#dfdfdf'
                                        }} image={productInfo.productId && productInfo.productId.productId && productInfo.productId.productId.images.length > 0 && productInfo.productId.productId.images[0]}
                                        />
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 10px 0px' }}>
                                        <Grid container>
                                            <Typography
                                                style={{
                                                    fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                    overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                }}>
                                                {productInfo.productName}
                                            </Typography>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2}>
                                                <span style={{ color: 'green', fontSize: '12px', paddingRight: '12px' }}><b>{productInfo.productId && productInfo.productId.discountRate}%</b></span>
                                            </Grid>
                                            <Grid item xs={7} sm={7} md={7} style={{ textAlign: 'right', paddingTop: '5px' }}>
                                                <span style={{ fontSize: '12px', paddingRight: '12px' }}><b>Quantity: </b></span>
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'right' }}>
                                                <TextField type="number" name="quantity" value={quantity} size="small" onChange={(e) => setQuantity(e.target.value)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ padding: '0px 10px' }}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px' }}><b>Brand: </b>{(productInfo.brandId && productInfo.brandId.name)}</span>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <span style={{ fontSize: '12px', paddingRight: '10px' }}><b>Supplier: </b>{productInfo.merchant}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                            <Card style={{ justifyContent: 'space-between', margin: '8px', boxShadow: 'none', padding: '5px 10px', backgroundColor: '#00ffb33d' }}>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={12}>
                                        <span style={{ fontSize: '13px' }}><b>PRICE DETAILS TOTAL ({quantity} ITEMS)</b></span>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Total MRP Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>₹{(productInfo.productId.mrp) * quantity}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Discount Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>-₹{(productInfo.productId.mrp - productInfo.productId.merchantPriceForFarmer) * quantity}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Total Order Amount</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>₹{(productInfo.productId.merchantPriceForFarmer) * quantity}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px' }}><b>Delivery Charges</b></span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px' }}>FREE</span>
                                    </Grid>
                                </Grid>
                                <hr style={{ margin: '7px 0px' }} />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={8}>
                                        <span style={{ fontSize: '13px', fontWeight: 900 }}>Total AMOUNT</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span style={{ fontSize: '13px', fontWeight: 900 }}>₹{(productInfo.productId.merchantPriceForFarmer) * quantity}</span>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button style={{ backgroundColor: '#FF5722', padding: '5px', margin: '10px 10px', fontSize: '11px', color: '#fff', border: '1px solid #ff3d00', width: '-webkit-fill-available' }}
                                disabled={progress} onClick={(e) => saveOrders()}>CONFIRM ORDER</Button>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                            <Button style={{ backgroundColor: '#0000003b', padding: '2px', margin: '0px 10px 10px', border: '1px solid #0000004f', fontSize: '11px', color: '#000000c7', width: '-webkit-fill-available' }}
                                onClick={(e) => setOpenProdDialog(false)}>CANCEL</Button>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            <Dialog onClose={handleDialogClose} fullWidth={true} maxWidth='lg' open={confirm}>
                <DialogTitle style={{ padding: '15px 0px 0px', textAlign: 'center' }}><CheckCircle style={{ fontSize: '40px', color: 'green' }} /></DialogTitle>
                <div style={{ textAlign: 'center', color: 'green', fontSize: '14px', padding: '10px 30px 10px' }}><b>Thank you for the Shopping!</b></div>
            </Dialog>
        </React.Fragment>
    );
}

export default ProductOrders;