import { getAxiosInstance } from '../configs/axios.config';
import { createContext } from 'react';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_PRODUCT_ORDERS_PROGRESS = "GET_PRODUCT_ORDERS_PROGRESS";
const GET_PRODUCT_ORDERS_SUCCESS = "GET_PRODUCT_ORDERS_SUCCESS";
const GET_PRODUCT_ORDERS_FAILURE = "GET_PRODUCT_ORDERS_FAILURE";

export const getProductList = (dispatch) => {
    dispatch({ type: GET_PRODUCT_ORDERS_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_PROD_LIST, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_PRODUCT_ORDERS_SUCCESS, productList: response.data });
        }).catch(function (error) {
            dispatch({ type: GET_PRODUCT_ORDERS_FAILURE });
        });
}

let initialState = {
    productList: [],
    isInProgress: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_ORDERS_PROGRESS:
            return { ...state, isInProgress: true };
        case GET_PRODUCT_ORDERS_SUCCESS:
            return { ...state, productList: action.productList, isInProgress: false };
        case GET_PRODUCT_ORDERS_FAILURE:
            return { ...state, isInProgress: false };
        default:
            return state;
    }
}

export default reducer;