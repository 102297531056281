export const setCounter = (dispatch) => {
    dispatch({ type: "TEST", num: 5 })
}

function reducer(state = { num: 0 }, action) {
    switch (action.type) {
        case "TEST":
            return { ...state, num: action.num };
        default:
            return state;
    }
}

export default reducer;