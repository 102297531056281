import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getStoreCustOrders } from '../reducers/cust.order.reducer';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import {
    Paper, AppBar, IconButton, Toolbar, Divider, DialogActions, DialogTitle,
    Typography, Grid, Card, CardContent, CardMedia,
    InputBase, Dialog, ListItemIcon, DialogContent,
    ListItem, ListItemText, Button,
} from '@material-ui/core';

import {
    ArrowBackIos, Search, Home, Notifications, ShoppingBasket, Dehaze, Person, Phone
} from '@material-ui/icons';

import Progress from './progress';
import { getFullDate } from '../configs/date.config';
import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
import { makeStyles } from '@material-ui/core/styles';

const { api } = require('../configs/constants');

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    fabButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px'
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    cover: {
        width: '72px',
        height: '72px',
        margin: '10px 10px 10px 10px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));
const status = { '0': 'Pending', '1': 'Confirmed', '2': 'Canceled', '3': 'Delivered', '4': 'Dispatched', '5': 'Returned', '6': 'PaymentRecevied' }
function CustOrder() {
    const [openOrderInfo, setOpenOrderInfo] = React.useState(false);
    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
    const [order, setOrder] = React.useState();
    const [orderId, setOrderId] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [progress, setProgress] = React.useState(false);
    const [flag, setFlag] = React.useState(false);
    const custOrder = useSelector(state => state.custOrder);
    const [mrp, setMrp] = React.useState(0);
    const [disMrp, setDisMrp] = React.useState(0);
    const [qty, setQty] = React.useState(0);
    const dispatch = useDispatch();
    const classes = useStyles();

    // Fetch store cust orders
    useEffect(() => { getStoreCustOrders(flag, dispatch) }, []);

    // Fetch store cust orders
    useEffect((didUpdate) => { getStoreCustOrders(flag, dispatch) }, []);

    const getorders = (flag) => {
        getStoreCustOrders(flag, dispatch);
        setFlag(flag);
    }

    const goBack = () => {
        window.history.back();
    }

    const openCanceldDialog = (id) => {
        setOpenCancelDialog(true);
        setOrderId(id);
    }

    const handleClickOpenOrder = (order) => {
        setOpenOrderInfo(true);
        setOrder(order)
        let disMrp = order ? order.products.reduce((a, b) => +a + +(b.discPrice * b.quantity), 0) : 0;
        let mrp = order ? order.products.reduce((a, b) => +a + +(b.mrp * b.quantity), 0) : 0;
        let qty = order ? order.products.reduce((a, b) => +a + +b.quantity, 0) : 0;
        setMrp(mrp);
        setDisMrp(disMrp);
        setQty(qty);
    }

    const handleClose = () => {
        setOpenOrderInfo(false);
        setOpenCancelDialog(false);
        setOrder(null)
    }

    const cancelOrder = () => {
        setProgress(true);
        let axios = getAxiosInstance();
        const user = authDetails();
        axios.put(api.CANCEL_ORDER, {
            userId: user.id,
            id: orderId,
            reason: reason,
        }).then(function (response) {
            getStoreCustOrders(flag, dispatch)
            setOpenCancelDialog(false);
        }).catch(function (error) {
            setProgress(false)
        });
    }

    const deliverOrder = (id) => {
        setProgress(true);
        let axios = getAxiosInstance();
        const user = authDetails();
        axios.put(api.DELIVER_ORDER, {
            userId: user.id,
            id: id,
        }).then(function (response) {
            getStoreCustOrders(flag, dispatch)
            setOpenOrderInfo(false);
        }).catch(function (error) {
            setProgress(false)
        });
    }

    return (
        <React.Fragment>
            {custOrder.isInProgress && <Progress open={custOrder.isInProgress} />}
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}><b>Online Orders</b></Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction='row' justify='space-between' style={{ backgroundColor: '#f1f5f8', padding: '0px 10px 4px' }}>
                <Grid container item xs={12}>
                    <Grid item xs={6} style={{ backgroundColor: (flag == false && '#ffe4bb'), textAlign: 'center' }}>
                        {flag == false ?
                            <Typography style={{ color: 'orange', fontSize: '13px' }}>Pending Orders</Typography> :
                            <Typography style={{ color: 'orange', fontSize: '13px' }} onClick={(e) => getorders(false)}>Pending Orders</Typography>
                        }
                    </Grid>
                    <Grid item xs={6} style={{ backgroundColor: (flag == true && '#b8ffbb'), textAlign: 'center' }}>
                        {flag == true ?
                            <Typography style={{ color: 'green', fontSize: '13px' }}>Deliverd Orders</Typography> :
                            <Typography style={{ color: 'green', fontSize: '13px' }} onClick={(e) => getorders(true)}>Deliverd Orders</Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='row' justify='space-between' style={{ backgroundColor: '#f1f5f8', padding: '5px 10px 10px' }}>
                <Grid item xs={12} container style={{ width: '-webkit-fill-available' }}>
                    <Paper component="form" className={classes.root} style={{ backgroundColor: '#fff' }}>
                        <InputBase
                            className={classes.input}
                            placeholder="Search Order..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton type="submit" className={classes.iconButton} aria-label="search">
                            <Search />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px', backgroundColor: '#f1f5f8' }}>
                {custOrder.custOrders.map((order) => {
                    return (
                        <Grid item xs={12} sm={12} md={12}>
                            <Card style={{ marginBottom: '8px', display: 'flex' }}>
                                <CardContent style={{ padding: '8px' }}>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item xs={12} style={{ paddingBottom: '5px' }}><span style={{ fontSize: '12px' }}><b>ORDER # </b>{order.storeId + order.totalPrice}</span></Grid>
                                        <Grid item style={{ color: '#000000e3' }} xs={8}>
                                            <ListItem style={{ padding: '0px' }}>
                                                <ListItemIcon style={{ minWidth: '28px' }}>
                                                    <Person />
                                                </ListItemIcon>
                                                <ListItemText primary={<span style={{ fontSize: '13px', color: 'black' }}>{order.customerName}</span>} />
                                            </ListItem>
                                        </Grid>
                                        <Grid item style={{ color: '#000000e3' }} xs={4}>
                                            <ListItem style={{ padding: '0px' }}>
                                                <ListItemIcon style={{ minWidth: '28px' }}>
                                                    <Phone />
                                                </ListItemIcon>
                                                <ListItemText primary={<span style={{ fontSize: '13px', color: 'black' }}>{order.custMobileNo}</span>} />
                                            </ListItem>
                                        </Grid>
                                        <Grid item xs={4}><span style={{ fontSize: '12px' }}><b>ORDER PLACED</b></span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }}><span style={{ fontSize: '12px' }}><b>TOTAL ITEMS</b></span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }}><span style={{ fontSize: '12px', color: 'black' }}>TOTAL AMOUNT</span></Grid>
                                        <Grid item xs={4} ><span style={{ fontSize: '12px' }}>{getFullDate(order.addedOn)}</span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }}><span style={{ fontSize: '12px', color: 'black' }}>{order.totalItems}</span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }}><span style={{ fontSize: '13px', color: 'black' }}>₹{order.totalPrice}</span></Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' style={{ textAlign: 'right' }}>
                                        <Grid item xs={12}>
                                            <Button style={{ marginRight: '10px', padding: '1px 10px', fontSize: '11px', borderRadius: '11px', color: '#fff', backgroundColor: '#3e3eff', border: '1px solid blue' }} onClick={() => { handleClickOpenOrder(order) }}>ORDER DETAILS</Button>
                                            <Button style={{ padding: '1px 12px', fontSize: '11px', borderRadius: '11px', color: 'black', backgroundColor: (order.currentStatus == '0' ? '#a6a6ff' : '#0000003b'), border: (order.currentStatus == '0' ? '1px solid blue' : '1px solid #0000008a') }} disabled={order.currentStatus != '0' ? true : false} onClick={(e) => openCanceldDialog(order._id)}>CANCEL ORDER</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
            </Grid>
            {openOrderInfo && <Dialog open={openOrderInfo} aria-labelledby="order-info" fullScreen >
                <DialogContent style={{ padding: '0px', backgroundColor: '#f1f5f8' }}>
                    <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                        <Toolbar>
                            <IconButton edge="start" aria-label="menu" onClick={handleClose}>
                                <ArrowBackIos style={{ color: 'black' }} />
                            </IconButton>
                            <Typography className={classes.title}><b>Online Orders</b></Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                        <Card style={{ justifyContent: 'space-between', margin: '10px', padding: '9px', border: '1px solid #cecece', boxShadow: 'none' }}>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item style={{ color: '#000000e3' }} xs={8}>
                                    <ListItem style={{ padding: '0px' }}>
                                        <ListItemIcon style={{ minWidth: '28px' }}>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '13px', color: 'black' }}>{order.customerName}</span>} />
                                    </ListItem>
                                </Grid>
                                <Grid item style={{ color: '#000000e3' }} xs={4}>
                                    <ListItem style={{ padding: '0px' }}>
                                        <ListItemIcon style={{ minWidth: '28px' }}>
                                            <Phone />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '13px', color: 'black' }}>{order.custMobileNo}</span>} />
                                    </ListItem>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: '13px' }}><b>ORDER # </b> {order.storeId + order.totalPrice}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction='row' justify='space-between' style={{ paddingTop: '7px' }}>
                                <Grid item xs={6}><span style={{ fontSize: '12px' }}><b>ORDER PLACED</b></span></Grid>
                                <Grid item xs={6}><span style={{ fontSize: '12px' }}><b>STATUS</b></span></Grid>
                                <Grid item xs={6}><span style={{ fontSize: '12px' }}>{getFullDate(order.addedOn)}</span></Grid>
                                <Grid item xs={6}><span style={{ fontSize: '12px', color: (order.currentStatus == '2' ? 'red' : 'green') }}>{status[order.currentStatus]}</span></Grid>
                            </Grid>
                            {order.products.map(function (orderInfo, index) {
                                return (
                                    <Grid container item xs={12} direction='row'>
                                        <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                            <CardMedia style={{
                                                inlineSize: 'fit-content', margin: '10px 0px', border: '1px solid #f1f5f8',
                                                textAlign: 'center', height: '70px', width: '70px', backgroundColor: '#f1f5f8'
                                            }}
                                                image={orderInfo.image}
                                            />
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 0px 0px 0px' }}>
                                            <Grid container>
                                                <Typography
                                                    style={{
                                                        fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                        overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                    }}> {orderInfo.productName}
                                                </Typography>
                                            </Grid>
                                            <Grid container style={{ paddingTop: '10px' }}>
                                                <Grid item xs={5}>
                                                    <span style={{ fontSize: '13px' }}><b style={{ paddngRight: '10px' }}>Quantity: </b> {orderInfo.quantity + ' '}</span>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <span style={{ paddingTop: '5px' }}> <span style={{ fontSize: '13px', fontFamily: 'sans-serif', marginRight: '10px' }}>{'₹' + orderInfo.discPrice}</span>
                                                        <span style={{ textDecorationLine: 'line-through', fontSize: '13px', color: '#878787', marginRight: '10px' }}>
                                                            {'₹' + orderInfo.mrp}
                                                        </span>
                                                        {/* <span style={{ color: 'black', fontSize: '13px' }}><b>{'  ' + (orderInfo.discount && orderInfo.discount) + '% off'}</b> </span> */}
                                                    </span>
                                                </Grid>
                                                {orderInfo.avl == false &&
                                                    <Grid item xs={12}>
                                                        <span style={{ fontSize: '13px', color: 'red' }}><b>Out of Stock, Please order now!</b></span>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid container>
                                                <Grid xs={6} item>
                                                    <span style={{ fontSize: '12px' }}><b>Brand: </b> {orderInfo.brandId}</span>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <span style={{ fontSize: '12px' }}><b>Supllier: </b> {orderInfo.merchantId}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Card>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', padding: '10px 0px' }}>
                            <Card style={{ justifyContent: 'space-between', boxShadow: 'none', padding: '5px 20px', backgroundColor: '#00ffb33d' }}>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={12}>
                                        <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>PRICE DETAILS TOTAL ITEMS </b>{qty}</span>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Total MRP Amount</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>₹{mrp}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Discount Amount</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>₹{disMrp - mrp}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Total Order Amount</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>₹{disMrp}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Delivery Charges</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>FREE</span>
                                    </Grid>
                                </Grid><hr />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '13px' }}><b>Total</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '13px' }}><b>₹{disMrp}</b></span>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} style={{ textAlign: 'center', position: 'fixed', bottom: 0, marginBottom: '35px', width: '-webkit-fill-available' }}>
                            <Button style={{ backgroundColor: ((order.isOrderDelivered || order.isOrderCanceled) ? '#0000003b' : '#FF5722'), padding: '5px', margin: '10px 10px', fontSize: '11px', color: '#fff', width: '-webkit-fill-available' }}
                                disabled={order.isOrderDelivered || order.isOrderCanceled} onClick={(e) => deliverOrder(order._id)} >ORDER DELIVERY</Button>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: '35px' }}></div>
                    <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            }
            {openCancelDialog &&
                <Dialog fullWidth={true} maxWidth='lg' open={openCancelDialog}>
                    <DialogTitle style={{ padding: '15px 0px 0px', textAlign: 'center', fontSize: '14px' }}><b>Please field the canceld order reason</b></DialogTitle>
                    <ValidatorForm onSubmit={cancelOrder}>
                        <DialogContent style={{ padding: '20px 15px 50px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextValidator id="reason" name="reason" label="Reason" autoFocus={true}
                                        value={reason} onChange={(e) => setReason(e.target.value)}  /**autoComplete="off" */
                                        validators={['required']} errorMessages={['Please Insert Reason']}
                                        fullWidth variant="outlined" multiline />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={2}>
                                <Grid item xs={6} style={{ paddingRight: '4px' }}>
                                    <Button disabled={progress} type='submit' variant="contained" style={{ fontSize: '11px', padding: '4px 0px', width: '100%', backgroundColor: '#1f1fff', borderRadius: '0px', border: '1px solid blue', color: '#fff' }}>SAVE</Button>
                                </Grid>
                                <Grid item xs={6} style={{ paddingLeft: '4px' }}>
                                    <Button variant="contained" onClick={handleClose} style={{ fontSize: '11px', padding: '4px', width: '100%', backgroundColor: '#1f1fff54', borderRadius: '0px', border: '1px solid blue', color: 'black' }}>CANCEL</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            }
        </React.Fragment>

    );
}

export default CustOrder;