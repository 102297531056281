import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    AppBar, IconButton, Toolbar, Paper, Divider, InputBase, Tabs, Tab, Badge, TextField, ListItem, ListItemText, ListItemIcon,
    Typography, Grid, Fab, Button, Card, CardMedia, DialogTitle, Dialog, DialogContent
} from '@material-ui/core';
import {
    ArrowBackIos, Search, Home, Notifications, ShoppingBasket, Dehaze, AddShoppingCart, CheckCircle, Check
} from '@material-ui/icons';
import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
import StoreProdStock from './store.prod.stock';
import { makeStyles } from '@material-ui/core/styles';
const apiConfig = require('../configs/constants');

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    fabButton: {
        position: 'fixed',
        bottom: '50px',
        right: '20px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}));

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function StoreOrder() {
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const goBack = () => {
        window.history.back();
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        let state = { ...history.location.state };
        state.stock = newValue;
        history.replace({ ...history.location, state });
    };

    return (
        <React.Fragment>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}><b>Stock on Hand</b></Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction='row' justify='space-between' style={{ backgroundColor: '#f1f5f8', padding: '5px 10px 10px' }}>
                <Grid item xs={12} container style={{ width: '-webkit-fill-available' }}>
                    <Paper component="form" className={classes.root} style={{ backgroundColor: '#fff' }}>
                        <InputBase
                            className={classes.input}
                            placeholder="Search Order..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton type="submit" className={classes.iconButton} aria-label="search">
                            <Search />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction='row' justify='space-between' style={{ backgroundColor: '#f1f5f8', padding: '0px 10px 10px' }}>
                <Grid item xs={12} container style={{ width: '-webkit-fill-available' }}>
                    <AppBar position="static" color="default" style={{ marginBottom: '10px' }}>
                        <Tabs value={value} onChange={handleChange} variant="fullWidth">
                            <Tab label="On Hand" style={{ color: 'green', fontSize: '12px', padding: '0px' }} />
                            <Tab label="Running Fast" style={{ color: 'orange', fontSize: '12px', padding: '0px' }} o />
                            <Tab label="Out of Stock" style={{ color: 'red', fontSize: '12px', padding: '0px' }} />
                            <Tab label="Add New" style={{ color: 'blue', fontSize: '12px', padding: '0px' }} />
                        </Tabs>
                    </AppBar>
                    {value === 0 && <TabContainer><StoreProdStock /></TabContainer>}
                    {value === 1 && <TabContainer><StoreProdStock /></TabContainer>}
                    {value === 2 && <TabContainer><StoreProdStock /></TabContainer>}
                    {value === 3 && <TabContainer><StoreProdStock /></TabContainer>}
                </Grid>
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default StoreOrder;