import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { getStoreCounterOrders } from '../reducers/cust.order.reducer';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import {
    Paper, AppBar, IconButton, Toolbar,
    Typography, Grid, Card, CardContent, CardMedia,
    Fab, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button, InputAdornment, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';

import {
    ArrowBackIos, Person, Phone, PostAdd, Home, Notifications, ShoppingBasket, Dehaze
} from '@material-ui/icons';
import { appRoutes } from '../configs/constants';
import Progress from './progress';
import { getFullDate } from '../configs/date.config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    fabButton: {
        position: 'fixed',
        bottom: '50px',
        right: '20px'
    },
    cover: {
        width: '72px',
        height: '72px',
        margin: '10px 10px 10px 10px',
        backgroundColor: '#fff',
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));

function CounterOrder() {
    const [openCustomer, setOpenCustomer] = React.useState(false);
    const [openOrderInfo, setOpenOrderInfo] = React.useState(false);
    const [order, setOrder] = React.useState(false);
    const custOrder = useSelector(state => state.custOrder);
    const [totalMRP, setTotalMRP] = React.useState(0);
    const [name, setName] = React.useState('');
    const [mobileNo, setMobileNo] = React.useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    // Fetch store cust orders
    useEffect(() => { getStoreCounterOrders(dispatch) }, []);

    const goBack = () => {
        history.push({ pathname: appRoutes.HOME });
    }

    const handleClickOpen = () => {
        setOpenCustomer(true);
    }

    const handleClickOpenOrder = (order) => {
        let totalMrp = order.products && order.products.length > 0 ? order.products.reduce((a, b) => +a + +(b.mrp * b.quantity), 0) : 0
        setTotalMRP(totalMrp);
        setOpenOrderInfo(true);
        setOrder(order)
    }

    const handleClose = () => {
        setOpenOrderInfo(false);
        setOrder(null)
        setOpenCustomer(false);
    }

    const saveCustomerInfo = () => {
        const data = {
            customerName: name,
            mobileNo: mobileNo,
        }
        history.push({ pathname: appRoutes.PRODUCT_ORDERS });
        localStorage.setItem('customerInfo', JSON.stringify(data));
        setOpenCustomer(false);
    }

    return (
        <React.Fragment>
            {custOrder.isInProgress && <Progress open={custOrder.isInProgress} />}
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}><b>Counter Orders</b></Typography>
                </Toolbar>
            </AppBar>
            <Fab style={{ backgroundColor: '#FF5722', color: '#fff', zIndex: 1000 }} aria-label="add" className={classes.fabButton} onClick={handleClickOpen} >
                <PostAdd style={{ fontSize: '40px' }} />
            </Fab>
            <Grid container direction="row" style={{ padding: '10px', backgroundColor: '#f1f5f8' }}>
                {custOrder.storeCountOrderList.map((order, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={12}>
                            <Card style={{ marginBottom: '10px', display: 'flex' }}>
                                <CardContent style={{ padding: '8px' }} onClick={(e) => handleClickOpenOrder(order)}>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item xs={8}>
                                            <ListItemText style={{ margin: '0px' }} primary={<span style={{ fontSize: '13px' }}><b>LIST</b> # {order.storeId + index}</span>} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ListItemText style={{ margin: '0px' }} primary={<span style={{ fontSize: '13px' }}>{getFullDate(order.addedOn)}</span>} />
                                        </Grid>
                                        <Grid item style={{ color: '#000000e3' }} xs={8}>
                                            <ListItem style={{ padding: '0px' }}>
                                                <ListItemIcon style={{ minWidth: '28px' }}>
                                                    <Person />
                                                </ListItemIcon>
                                                <ListItemText primary={<span style={{ fontSize: '13px' }}>{order.customerName}</span>} />
                                            </ListItem>
                                        </Grid>
                                        <Grid item style={{ color: '#000000e3' }} xs={4}>
                                            <ListItem style={{ padding: '0px' }}>
                                                <ListItemIcon style={{ minWidth: '28px' }}>
                                                    <Phone />
                                                </ListItemIcon>
                                                <ListItemText primary={<span style={{ fontSize: '13px' }}>{order.custMobileNo}</span>} />
                                            </ListItem>
                                        </Grid>
                                        <Grid container direction='row'>
                                            <Grid item xs={8} style={{ color: '#000000e3', paddingLeft: '30px' }}>
                                                <ListItemText style={{ margin: '0px' }} primary={<span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>TOTAL ITEMS</b>  {order.totalItems}</span>} />
                                            </Grid>
                                            <Grid item xs={4} style={{ color: '#000000e3' }}>
                                                <ListItemText style={{ margin: '0px' }} primary={<span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>TOTAL</b>₹{order.totalPrice}</span>} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
            </Grid>
            {
                openOrderInfo && <Dialog open={openOrderInfo} onClose={handleClose} aria-labelledby="order-info" fullScreen >
                    <DialogContent style={{ padding: '0px' }}>
                        <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                            <Toolbar>
                                <IconButton edge="start" aria-label="menu" onClick={(e) => handleClose()}>
                                    <ArrowBackIos style={{ color: 'black' }} />
                                </IconButton>
                                <Typography className={classes.title}><b>View Customer Order</b></Typography>
                            </Toolbar>
                        </AppBar>
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', padding: '10px' }}>
                            <Grid container direction='row' justify='space-between'>
                                <Grid item style={{ color: '#000000e3' }} xs={8}>
                                    <ListItem style={{ padding: '0px' }}>
                                        <ListItemIcon style={{ minWidth: '28px' }}>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '13px' }}>{order.customerName}</span>} />
                                    </ListItem>
                                </Grid>
                                <Grid item style={{ color: '#000000e3' }} xs={4}>
                                    <ListItem style={{ padding: '0px' }}>
                                        <ListItemIcon style={{ minWidth: '28px' }}>
                                            <Phone />
                                        </ListItemIcon>
                                        <ListItemText primary={<span style={{ fontSize: '13px' }}>{order.custMobileNo}</span>} />
                                    </ListItem>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', margin: '10px 0px' }}>
                                <Card style={{ justifyContent: 'space-between', boxShadow: 'none', padding: '5px 10px', backgroundColor: '#00ffb33d' }}>
                                    <Grid container direction='row' justify='space-between' style={{ paddingBottom: '7px' }}>
                                        <Grid item xs={5}>
                                            <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>TOTAL ITEMS</b>  {order.totalItems}</span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>FINAL AMOUNT</b>₹{order.totalPrice}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item xs={5}>
                                            <span style={{ fontSize: '13px' }}><b>Total MRP Amount</b></span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span style={{ fontSize: '13px' }}>₹{totalMRP}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item xs={5}>
                                            <span style={{ fontSize: '13px' }}><b>Discount Amount</b></span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span style={{ fontSize: '13px' }}>₹{order.totalPrice - totalMRP}</span>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            {order.products.map(product => {
                                return (
                                    <Card style={{ justifyContent: 'space-between', marginBottom: '10px', border: '1px solid #cecece', boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                                        <div style={{ display: 'flex' }}>
                                            <CardMedia
                                                className={classes.cover}
                                                image={product.image}
                                                title={product.productName} />
                                            <CardContent style={{ padding: '7px' }}>
                                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '99%', whiteSpace: 'nowrap', }}></span>
                                                <Typography style={{
                                                    fontWeight: 500, color: '#282c3f', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                }}>{product.productName}</Typography>
                                                <Typography style={{ fontWeight: 500, color: '#282c3f', lineHeight: 1.57, fontSize: '13px', }}>
                                                    <span style={{ fontSize: '13px', marginRight: '5px' }}><b> {'₹' + (product.discPrice) + '   '}</b></span>
                                                    <span style={{ textDecorationLine: 'line-through', fontSize: '13px', color: '#878787' }}>
                                                        {'₹' + (product.mrp)}
                                                    </span>
                                                </Typography>
                                                <Typography><span style={{ fontSize: '13px' }}><b>Quantity : {product.quantity}</b></span></Typography>
                                            </CardContent>
                                        </div>
                                    </Card>
                                )
                            })
                            }
                        </Grid>
                    </DialogContent>
                    <div style={{ marginBottom: '35px' }}></div>
                    <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                    </Grid>
                </Dialog>
            }
            {
                openCustomer && <Dialog open={openCustomer} onClose={handleClose}>
                    <DialogTitle id="demand-product" style={{ textAlign: 'center', paddingTop: '30px' }}>New Customer</DialogTitle>
                    <ValidatorForm onSubmit={saveCustomerInfo}>
                        <DialogContent style={{ padding: '20px 15px 50px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextValidator id="name" name="name" label="Customer Name" autoFocus={true}
                                        value={name} onChange={(e) => setName(e.target.value)}  /**autoComplete="off" */
                                        validators={['required']} errorMessages={['Please Insert Customer Name']}
                                        fullWidth variant="outlined" InputLabelProps={{ shrink: true }}
                                        InputProps={{ startAdornment: (<InputAdornment position="start"><Person /></InputAdornment>) }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator id="mobileNo" name="mobileNo" label="Mobile Number" value={mobileNo} type='number'
                                        onChange={(e) => setMobileNo(e.target.value)} validators={['required']} errorMessages={['Please Insert Mobile Number']}
                                        fullWidth variant="outlined" InputLabelProps={{ shrink: true }}  /**autoComplete="off" */
                                        InputProps={{ startAdornment: (<InputAdornment position="start"><Phone /></InputAdornment>) }} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={2}>
                                <Grid item xs={6} style={{ paddingRight: '4px' }}>
                                    <Button type='submit' variant="contained" style={{ fontSize: '11px', padding: '4px 0px', width: '100%', backgroundColor: '#1f1fff', borderRadius: '0px', border: '1px solid blue', color: '#fff' }}>SAVE & CREATE LIST</Button>
                                </Grid>
                                <Grid item xs={6} style={{ paddingLeft: '4px' }}>
                                    <Button variant="contained" onClick={handleClose} style={{ fontSize: '11px', padding: '4px', width: '100%', backgroundColor: '#1f1fff54', borderRadius: '0px', border: '1px solid blue', color: 'black' }}>CANCEL</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            }
        </React.Fragment>

    );
}

export default CounterOrder;