import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    Paper, AppBar, Button, Toolbar, Typography, Grid, Card, CardContent, ListItem, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, ListItemText, ListItemIcon
} from '@material-ui/core';
import { ArrowBackIos, PersonPin, TrendingUp, Equalizer, Assessment, Home, Notifications, ShoppingBasket, Dehaze } from '@material-ui/icons';
import { getFullDate } from '../configs/date.config';
import { makeStyles } from '@material-ui/core/styles';
import { authDetails } from '../auth/authdetails';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', width: '97%', whiteSpace: 'nowrap', color: 'black', fontSize: '23px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));

function Notification() {
    const [openDemandProduct, setOpenDemandProduct] = React.useState(false);
    const [bgColor, setbgColor] = React.useState(0);
    const [quantity, setQuantity] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const productDemand = useSelector(state => state.productDemand);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const user = authDetails();

    const renderComponent = (no) => {
        if (no == 1) {
            history.push("/");
        } else if (no == 3) {
            history.push("/shopping");
        } else if (no == 4) {
            history.push("/settings");
        }
    }

    return (
        <React.Fragment>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <Typography className={classes.title}>Statistics</Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction="row" style={{ paddingTop: '10px' }}>
                <Grid container style={{ backgroundColor: '#cbcbcb', margin: '0px 10px', borderRadius: '10px' }} item xs={12}>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 0 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 0 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }}>D</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 1 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 1 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }}>W</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 2 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 2 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }}>M</Grid>
                    <Grid item xs={3} style={{ textAlign: 'center', borderRadius: '10px', border: (bgColor == 3 ? '1px solid gray' : 'unset'), backgroundColor: (bgColor == 3 ? '#fff' : '#cbcbcb'), paddingTop: '5px' }}>Y</Grid>
                </Grid>
            </Grid><div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Home style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(1)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(3)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(4)} /></Button>
                </Grid>
            </Grid >
        </React.Fragment>

    );
}

export default Notification;