import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_STORE_NAME_PROGRESS = "GET_STORE_NAME_PROGRESS";
const GET_STORE_NAME_SUCCESS = "GET_STORE_NAME_SUCCESS";
const GET_STORE_NAME_FAILURE = "GET_STORE_NAME_FAILURE";

export const getStoreName = (dispatch) => {
    dispatch({ type: GET_STORE_NAME_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_NAME, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_STORE_NAME_SUCCESS, storeName: response.data });
            localStorage.setItem('storeInfo', JSON.stringify(response.data));
        }).catch(function (error) {
            dispatch({ type: GET_STORE_NAME_FAILURE });
        });
}

let initialState = {
    storeName: null,
    isProgress: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_STORE_NAME_PROGRESS:
            return { ...state, isProgress: true };
        case GET_STORE_NAME_SUCCESS:
            return { ...state, storeName: action.storeName, isProgress: true };
        case GET_STORE_NAME_FAILURE:
            return { ...state, isProgress: true };
        default:
            return state;
    }
}

export default reducer;