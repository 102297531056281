import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_STORE_SELL_ORDERS_PROGRESS = "GET_STORE_SELL_ORDERS_PROGRESS";
const GET_STORE_SELL_ORDERS_SUCCESS = "GET_STORE_SELL_ORDERS_SUCCESS";
const GET_STORE_SELL_ORDERS_FAILURE = "GET_STORE_SELL_ORDERS_FAILURE";
const GET_SELL_BY_PRODUCT_PROGRESS = "GET_SELL_BY_PRODUCT_PROGRESS";
const GET_SELL_BY_PRODUCT_SUCCESS = "GET_SELL_BY_PRODUCT_SUCCESS";
const GET_SELL_BY_PRODUCT_FAILURE = "GET_SELL_BY_PRODUCT_FAILURE";
const GET_SELL_BY_CUST_SUCCESS = "GET_SELL_BY_CUST_SUCCESS";
const GET_SELL_BY_CUST_FAILURE = "GET_SELL_BY_CUST_FAILURE";

/** customer orders */
export const getSellOrdersCount = (dispatch) => {
    dispatch({ type: GET_STORE_SELL_ORDERS_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_SELL_ORDERS, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_STORE_SELL_ORDERS_SUCCESS, sellCount: response.data, totalItems: response.data.totalItems });
        }).catch(function (error) {
            dispatch({ type: GET_STORE_SELL_ORDERS_FAILURE });
        });
}

/**Category and product wise sells */
export const getStoreSellsByProduct = (dispatch) => {
    dispatch({ type: GET_SELL_BY_PRODUCT_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_SELL_PRODUCTS, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_SELL_BY_PRODUCT_SUCCESS, mySellProfits: response.data, totalItems: response.data.totalItems });
        }).catch(function (error) {
            dispatch({ type: GET_SELL_BY_PRODUCT_FAILURE });
        });
}

/**customer wise sells */
export const getStoreSellsByCustomer = (dispatch) => {
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_SELL_CUST, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_SELL_BY_CUST_SUCCESS, custSellProducts: response.data });
        }).catch(function (error) {
            dispatch({ type: GET_SELL_BY_CUST_FAILURE });
        });
}

function attachProductList(productData) {
    let productList = [...new Set(productData.map(item => item.productId))];
    let products = [];
    for (let i = 0; i <= productList.length; i++) {
        let productId = productList[i];
        let sale = 0;
        let profit = 0;
        let productName = '';
        productData.map(function (prod) {
            if (prod.productId == productId) {
                sale += (prod.discPrice * prod.quantity);
                profit += (prod.societyPrice * prod.quantity);
                productName = prod.productName;
            }
        })
        let product = {
            productName: productName,
            sale: sale,
            profit: profit,
        }
        if (product.productName != "" && product.sale > 0) {
            products.push(product);
        }
    }
    let productInfo = products.sort(function (a, b) { return b.sale - a.sale });
    return productInfo;
}

function attachCategoryList(productData) {
    var products = [];
    if (productData.length > 0) {
        let categoryList = [...new Set(productData.map(item => item.category._id))];
        for (let i = 0; i <= categoryList.length; i++) {
            let categoryId = categoryList[i];
            let sale = 0;
            let profit = 0;
            let category = '';
            productData.map(function (prod) {
                if (prod.category._id == categoryId) {
                    sale += (prod.discPrice * prod.quantity);
                    profit += (prod.societyPrice * prod.quantity);
                    category = prod.category.title;
                }
            })
            let product = {
                category: category,
                sale: sale,
                profit: profit,
            }
            if (product.category != "" && product.sale > 0) {
                products.push(product);
            }
        }
    }
    let productList = products.sort(function (a, b) { return b.sale - a.sale });
    return productList;
}

let initialState = {
    sellCount: null,
    categorySells: [],
    mySellProdProfits: [],
    custSellProducts: [],
    totalItems: 0,
    isInProgress: false
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_STORE_SELL_ORDERS_PROGRESS:
            return { ...state };
        case GET_STORE_SELL_ORDERS_SUCCESS:
            return { ...state, sellCount: action.sellCount };
        case GET_STORE_SELL_ORDERS_FAILURE:
            return { ...state };
        case GET_SELL_BY_PRODUCT_PROGRESS:
            return { ...state, isInProgress: true };
        case GET_SELL_BY_PRODUCT_SUCCESS:
            return { ...state, categorySells: attachCategoryList(action.mySellProfits), mySellProdProfits: attachProductList(action.mySellProfits), isInProgress: false };
        case GET_SELL_BY_PRODUCT_FAILURE:
            return { ...state, isInProgress: false };
        case GET_SELL_BY_CUST_SUCCESS:
            return { ...state, custSellProducts: action.custSellProducts };
        case GET_SELL_BY_CUST_FAILURE:
            return { ...state };
        default:
            return state;
    }
}

export default reducer;