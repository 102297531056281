import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const apiConfig = require('../configs/constants');

const GET_PRODUCT_DEMANDS_PROGRESS = "GET_PRODUCT_DEMANDS_PROGRESS";
const GET_PRODUCT_DEMANDS_SUCCESS = "GET_PRODUCT_DEMANDS_SUCCESS";
const GET_PRODUCT_DEMANDS_FAILURE = "GET_PRODUCT_DEMANDS_FAILURE";
const SET_MESSAGE = 'SET_MESSAGE';

export const getStoreProductDemands = (dispatch) => {
    dispatch({ type: GET_PRODUCT_DEMANDS_PROGRESS });
    const user = authDetails();
    let axios = getAxiosInstance();
    axios.get(apiConfig.api.GET_STORE_PRODUCT_DEMANDS, { params: { storeId: user.storeId } })
        .then(function (response) {
            dispatch({ type: GET_PRODUCT_DEMANDS_SUCCESS, productDemands: response.data });
        }).catch(function (error) {
            dispatch({ type: GET_PRODUCT_DEMANDS_FAILURE });
        });
}

let initialState = {
    productDemands: [],
    isInProgress: false,
    message: null
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_DEMANDS_PROGRESS:
            return { ...state, isInProgress: true };
        case GET_PRODUCT_DEMANDS_SUCCESS:
            return { ...state, productDemands: action.productDemands, isInProgress: false };
        case GET_PRODUCT_DEMANDS_FAILURE:
            return { ...state, isInProgress: false };
        case SET_MESSAGE:
            return { ...state, message: action.message };
        default:
            return state;
    }
}

export default reducer;