import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStoreProductDemands } from '../reducers/product.demand.reducer';
import {
    Paper, AppBar, IconButton, Toolbar, Divider, Typography, Grid, Card, CardContent, CardMedia,
    InputBase, Dialog, DialogContent, DialogTitle, Button, CircularProgress, Avatar
} from '@material-ui/core';
import { ArrowBackIos, Search, Home, Notifications, ShoppingBasket, Dehaze, Publish, CheckCircle } from '@material-ui/icons';
import Progress from './progress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getFullDate } from '../configs/date.config';
import { makeStyles } from '@material-ui/core/styles';
import { getAxiosInstance } from '../configs/axios.config';
import { authDetails } from '../auth/authdetails';
const { api } = require('../configs/constants');

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: 'black',
        fontSize: '23px'
    },
    fabButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px'
    },

    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    cover: {
        width: '72px',
        height: '72px',
        margin: '10px 10px 10px 10px'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));

function StoreProductDemands() {
    const [openOrderInfo, setOpenOrderInfo] = React.useState(false);
    const [order, setOrder] = React.useState(false);
    const productDemand = useSelector(state => state.productDemand);
    const [mrp, setMrp] = React.useState(0);
    const [progress, setProgress] = React.useState(false);
    const [payModel, setPayModel] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [viewImg, setViewImg] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);
    const [disMrp, setDisMrp] = React.useState(0);
    const [amt, setAmt] = React.useState('');
    const [checkNo, setCheckNo] = React.useState('');
    const [orderInfo, setOrderInfo] = React.useState(null);
    const [img, setImg] = React.useState(null);
    const [qty, setQty] = React.useState(0);
    const dispatch = useDispatch();
    const classes = useStyles();

    // Fetch store cust orders
    useEffect(() => { getStoreProductDemands(dispatch) }, []);

    const goBack = () => {
        window.history.back();
    }

    const paymentReceived = () => {

    }

    const handleClickOpenOrder = (order) => {
        setOpenOrderInfo(true);
        setOrder(order)
        let disMrp = order ? order.products.reduce((a, b) => +a + +(b.merchantPriceForFarmer * b.quantity), 0) : 0;
        let mrp = order ? order.products.reduce((a, b) => +a + +(b.mrp * b.quantity), 0) : 0;
        let qty = order ? order.products.reduce((a, b) => +a + +b.quantity, 0) : 0;
        setMrp(mrp);
        setDisMrp(disMrp);
        setQty(qty);
    }

    const handleClose = () => {
        setOpenOrderInfo(false);
        setOrder(null);
        setPayModel(false)
        setOrderInfo(null);
        setViewImg(false);
        setConfirm(false);
    }

    const handleOnClose = (open) => {
        setViewImg(open);
    }

    const recivedOrder = (orderId) => {
        setProgress(true)
        let axios = getAxiosInstance();
        const user = authDetails();
        axios.put(api.RECIVED_ORDER, {
            userId: user.id,
            orderId: orderId,
            recived: true,
            recivedOn: Date.now(),
        }).then(function (response) {
            setProgress(false);
            getStoreProductDemands(dispatch);
        }).catch(function (error) {
            setProgress(false)
        });
    }

    const openPayModel = (info) => {
        setPayModel(true);
        setOrderInfo(info)
    }

    const uploadFile = (e) => {
        setLoading(true);
        const bodyFormData = new FormData();
        bodyFormData.append('file', e.target.files[0]);
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        let axios = getAxiosInstance();
        axios.post('/upload-image', bodyFormData, config
        ).then(function (response) {
            setLoading(false);
            setImg(response.data.secureUrl);
        }).catch(function (error) {
            setLoading(false);
        });
    }

    const savePaySlip = () => {
        setProgress(true)
        let axios = getAxiosInstance();
        const user = authDetails();
        axios.put(api.RECIVED_PAYMENT, {
            userId: user.id,
            orderId: orderInfo._id,
            payMode: true,
            paySlip: img,
            checkNo: checkNo,
            checkAmt: amt,
            paymentReceivedOn: Date.now(),
        }).then(function (response) {
            setProgress(false);
            setConfirm(true);
            getStoreProductDemands(dispatch);
        }).catch(function (error) {
            setProgress(false)
        });
    }

    const openImg = (info) => {
        setViewImg(true);
        setOrderInfo(info);
    }

    return (
        <React.Fragment>
            {productDemand.isInProgress && <Progress open={productDemand.isInProgress} />}
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar>
                    <IconButton edge="start" aria-label="menu" onClick={goBack}>
                        <ArrowBackIos style={{ color: 'black' }} />
                    </IconButton>
                    <Typography className={classes.title}><b>My Orders</b></Typography>
                </Toolbar>
            </AppBar>
            <Grid container direction='row' justify='space-between' style={{ backgroundColor: '#f1f5f8', padding: '5px 10px 10px' }}>
                <Grid item xs={12} container style={{ width: '-webkit-fill-available' }}>
                    <Paper component="form" className={classes.root} style={{ backgroundColor: '#fff' }}>
                        <InputBase className={classes.input} placeholder="Search Order..." inputProps={{ 'aria-label': 'search google maps' }} />
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton type="submit" className={classes.iconButton} aria-label="search">
                            <Search />
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '0px 10px 10px', backgroundColor: '#f1f5f8' }}>
                {productDemand.productDemands.map((order) => {
                    return (
                        <Grid item xs={12} sm={12} md={12}>
                            <Card style={{ marginBottom: '8px', display: 'flex' }}>
                                <CardContent style={{ padding: '8px' }}>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item xs={12} style={{ paddingBottom: '5px' }}><span style={{ fontSize: '12px' }}><b>ORDER # </b>{order.orderId}</span></Grid>
                                        <Grid item xs={4}><span style={{ fontSize: '12px' }}><b>ORDER PLACED</b></span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }}><span style={{ fontSize: '12px' }}><b>STATUS</b></span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }} onClick={() => { handleClickOpenOrder(order) }}><span style={{ fontSize: '12px', color: 'blue' }}><u>ORDER DETAILS</u></span></Grid>
                                        <Grid item xs={4} ><span style={{ fontSize: '12px' }}>{getFullDate(order.addedOn)}</span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }}><span style={{ fontSize: '12px', color: 'green' }}>{order.orderStatus}</span></Grid>
                                        <Grid item xs={4} style={{ textAlign: 'center' }}><span style={{ fontSize: '13px', color: 'blue' }}><u>Invoice</u></span></Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' style={{ padding: '10px 0px' }}>
                                        <Grid item xs={5}>
                                            <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>TOTAL ITEMS</b> {order.quantity}</span>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}> TOTAL AMOUNT</b> ₹{order.totalFarPrice}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' style={{ textAlign: 'right' }}>
                                        <Grid item xs={12}>
                                            {order.recived ?
                                                <Button style={{ marginRight: '10px', padding: '1px 30px', fontSize: '11px', borderRadius: '11px', color: 'black', backgroundColor: '#80808033', border: '1px solid gray' }}>RECEVIED</Button> :
                                                <Button style={{ marginRight: '10px', padding: '1px 30px', fontSize: '11px', borderRadius: '11px', color: '#fff', backgroundColor: '#3e3eff', border: '1px solid blue' }} onClick={(e) => recivedOrder(order._id)}>RECEVIED</Button>
                                            }
                                            {order.payMode ?
                                                <Button style={{ padding: '1px 12px', fontSize: '11px', borderRadius: '11px', color: 'black', backgroundColor: '#80808033', border: '1px solid gray' }} onClick={(e) => openImg(order)}>VIEW PAYMENT</Button> :
                                                <Button style={{ padding: '1px 12px', fontSize: '11px', borderRadius: '11px', color: '#fff', backgroundColor: '#3e3eff', border: '1px solid blue' }} onClick={(e) => openPayModel(order)}>MAKE PAYMENT</Button>
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
            </Grid>
            {openOrderInfo && <Dialog open={openOrderInfo} aria-labelledby="order-info" fullScreen >
                <DialogContent style={{ padding: '0px', backgroundColor: '#f1f5f8' }}>
                    <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                        <Toolbar>
                            <IconButton edge="start" aria-label="menu" onClick={handleClose}>
                                <ArrowBackIos style={{ color: 'black' }} />
                            </IconButton>
                            <Typography className={classes.title}><b>View My Orders</b></Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available' }}>
                        {order.products.map(function (orderInfo, index) {
                            return (
                                <Card style={{ justifyContent: 'space-between', margin: '10px', padding: '9px', border: '1px solid #cecece', boxShadow: 'none' }}>
                                    <Grid container direction='row' justify='space-between'>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: '13px' }}><b>ORDER # </b> {order.orderId}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' style={{ paddingTop: '7px' }}>
                                        <Grid item xs={6}><span style={{ fontSize: '12px' }}><b>ORDER PLACED</b></span></Grid>
                                        <Grid item xs={6}><span style={{ fontSize: '12px' }}><b>STATUS</b></span></Grid>
                                        <Grid item xs={6}><span style={{ fontSize: '12px' }}>{getFullDate(order.addedOn)}</span></Grid>
                                        <Grid item xs={6}><span style={{ fontSize: '12px', color: 'green' }}>{order.orderStatus}</span></Grid>
                                    </Grid>
                                    <Grid container item xs={12} direction='row'>
                                        <Grid item xs={3} sm={3} md={3} style={{ textAlign: 'center' }}>
                                            <CardMedia style={{
                                                inlineSize: 'fit-content', margin: '10px 0px', border: '1px solid #f1f5f8',
                                                textAlign: 'center', height: '70px', width: '70px', backgroundColor: '#f1f5f8'
                                            }}
                                                image={orderInfo.productImg}
                                            />
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={9} style={{ padding: '10px 0px 0px 0px' }}>
                                            <Grid container>
                                                <Typography
                                                    style={{
                                                        fontWeight: 600, color: 'black', width: '97%', whiteSpace: 'nowrap',
                                                        overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.57, fontSize: '13px', marginBottom: 'inherit'
                                                    }}> {orderInfo.productName}
                                                </Typography>
                                            </Grid>
                                            <Grid container style={{ paddingTop: '10px' }}>
                                                <Grid item xs={5}>
                                                    <span style={{ fontSize: '13px' }}><b style={{ paddngRight: '10px' }}>Quantity: </b> {orderInfo.quantity + ' '}</span>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <span style={{ paddingTop: '5px' }}> <span style={{ fontSize: '13px', fontFamily: 'sans-serif', marginRight: '10px' }}>{'₹' + orderInfo.merchantPriceForFarmer}</span>
                                                        <span style={{ textDecorationLine: 'line-through', fontSize: '13px', color: '#878787', marginRight: '10px' }}>
                                                            {'₹' + orderInfo.mrp}
                                                        </span>
                                                        <span style={{ color: 'black', fontSize: '13px' }} >
                                                            <b>{'  ' + orderInfo.discount + '% off'}</b>
                                                        </span></span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid container>
                                                <Grid xs={6} item>
                                                    <span style={{ fontSize: '12px' }}><b>Brand: </b> {orderInfo.brand}</span>
                                                </Grid>
                                                <Grid xs={6} item>
                                                    <span style={{ fontSize: '12px' }}><b>Supllier: </b> {orderInfo.merchant}</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            )
                        })}
                        <Grid item sm={12} md={12} lg={12} style={{ width: '-webkit-fill-available', padding: '10px 0px' }}>
                            <Card style={{ justifyContent: 'space-between', boxShadow: 'none', padding: '5px 20px', backgroundColor: '#00ffb33d' }}>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={12}>
                                        <span style={{ fontSize: '13px' }}><b style={{ paddingRight: '10px' }}>PRICE DETAILS TOTAL ITEMS </b>{qty}</span>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Total MRP Amount</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>₹{mrp}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Discount Amount</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>₹{disMrp - mrp}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Total Order Amount</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>₹{disMrp}</span>
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}><b>Delivery Charges</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '12px' }}>FREE</span>
                                    </Grid>
                                </Grid><hr />
                                <Grid container direction='row' justify='space-between'>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '13px' }}><b>TOTAL AMOUNT</b></span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ fontSize: '13px' }}><b>₹{disMrp}</b></span>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <div style={{ marginBottom: '35px' }}></div>
                    <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px', backgroundColor: '#001dbb' }}><Home style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Notifications style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                        <Grid item style={{ width: '25%', textAlign: 'center' }}>
                            <Button style={{ padding: '6px' }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} /></Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            }
            {payModel &&
                <Dialog onClose={handleClose} fullWidth={true} maxWidth='md' open={payModel}>
                    <DialogTitle style={{ padding: '15px 0px 0px', textAlign: 'center' }}>Upload Payment Receipt</DialogTitle>
                    <ValidatorForm onSubmit={savePaySlip}>
                        <DialogContent style={{ padding: '6px 20px', overflow: 'hidden' }}>
                            <div style={{ textAlign: 'center', color: '#744200' }}><b>OrderId: #{orderInfo.orderId}</b></div>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <div style={{ padding: '12px 0px', textAlign: 'right' }}>
                                        <input accept="image/*" style={{ display: 'none' }} name="payImg" id="payImg" type="file" onChange={(e) => uploadFile(e)} />
                                        <label htmlFor="payImg">
                                            <Button variant="contained" component="span" disabled={loading}><Publish style={{ paddingRight: '6px', fontSize: '20px' }} />
                                                {loading ? "Uploading..." : "Pay Slip"}
                                                {loading ? <CircularProgress size={24} style={{ margin: '0px' }} /> : null}
                                            </Button>
                                        </label>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    {img && <Avatar style={{ margin: '16px 10px 0px', height: '30px', width: '30px', borderRadius: '0%', border: '1px solid gray', backgroundColor: 'white' }}>{img}</Avatar>}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextValidator id="amt" name="amt" label="Order Amount" autoFocus={true}
                                        value={amt} onChange={(e) => setAmt(e.target.value)}  /**autoComplete="off" */
                                        validators={['required']} errorMessages={['Please Insert Amount']} fullWidth variant="outlined" type='number' />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValidator id="checkNo" name="checkNo" label="Check Number" value={checkNo} fullWidth variant="outlined"
                                        onChange={(e) => setCheckNo(e.target.value)} validators={['required']} errorMessages={['Please Insert Check Number']} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <div style={{ padding: '20px 10px 10px', textAlign: 'center' }}>
                            {img &&
                                <Button variant="contained" fullWidth style={{ backgroundColor: '#3e3eff', color: '#fff', padding: '4px' }} type='submit'>Save</Button>
                            }
                        </div>
                    </ValidatorForm>
                </Dialog>
            }
            {viewImg &&
                <Dialog fullScreen open={viewImg}>
                    <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                        <Toolbar>
                            <IconButton edge="start" aria-label="menu" onClick={handleClose}><ArrowBackIos style={{ color: 'black' }} /></IconButton>
                            <Typography className={classes.title}><b>View Pay Slip</b></Typography>
                        </Toolbar>
                    </AppBar>
                    <CardMedia style={{
                        display: 'inline-block', backgroundPosition: 'center', backgroundSize: 'contain', textAlign: 'center', height: '100%',
                        backgroundRepeat: 'no-repeat', margin: '0px', border: '1px solid #f1f5f8', width: '100%', backgroundColor: '#f1f5f8'
                    }} image={orderInfo.paySlip} />
                </Dialog>
            }
            {confirm && <Dialog onClose={handleClose} fullWidth={true} maxWidth='lg' open={confirm}>
                <DialogTitle style={{ padding: '15px 0px 0px', textAlign: 'center' }}><CheckCircle style={{ fontSize: '40px', color: 'green' }} /></DialogTitle>
                <div style={{ textAlign: 'center', color: 'green', fontSize: '14px', padding: '10px 30px 10px' }}><b>Thank you!</b></div>
                <div style={{ textAlign: 'center', color: 'green', fontSize: '14px', padding: '0px 0px 10px' }}><b>Saving your payment details.</b></div>
            </Dialog>}
        </React.Fragment >
    );
}

export default StoreProductDemands;