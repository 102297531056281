import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { constants } from '../configs/constants';
import { getStoreName } from '../reducers/home.reducer';
import {
    Menu, MenuItem, Card, Grid, CardContent, DialogActions, Dialog, DialogTitle, DialogContent,
    Typography, Avatar, AppBar, Toolbar,
    IconButton, Button
} from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    ShoppingBasket, Dehaze, PersonOutlined, InsertDriveFileOutlined, Notifications,
    NearMeOutlined, FormatListBulletedOutlined, AccountCircle
} from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import { authDetails } from '../auth/authdetails';
import { makeStyles } from '@material-ui/core/styles';
import { appRoutes } from '../configs/constants';
import PromotionalOffer from './promotional.offers';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        color: 'black'
    },
    fab: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#1c47ff'
    },
}));


function Home() {
    const [openProfile, setOpenProfile] = React.useState(false);
    const [bgColor, setbgColor] = React.useState(1);
    const [name, setName] = React.useState('');
    const [mobileNo, setMobileNo] = React.useState();
    const [designation, setDesignation] = React.useState();
    const homeData = useSelector(state => state.homeData);
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    let storeUser = localStorage.getItem(constants.STORE_USER);

    useEffect(() => {
        if (homeData.isProgress == false) {
            getStoreName(dispatch);
        }
    })

    const handleClose = () => {
        setAnchorEl(null);
        setOpenProfile(false);
    };

    const renderComponent = (no) => {
        setbgColor(no);
        if (no == 4) {
            history.push("/settings");
        } else if (no == 3) {
            history.push("/shopping");
        } else if (no == 2) {
            history.push("/notification");
        }
    }

    const homeOptions = [{ title: 'Online Orders', icon: <PersonOutlined />, route: appRoutes.CUST_ONLINE_ORDERS, backgroundColor: '#f2dfaa', margin: '1px 5px 0px 0px' },
    { title: 'Counter Order', icon: <InsertDriveFileOutlined />, route: appRoutes.COUNTER_ORDERS, backgroundColor: '#b4f0e4', margin: '1px 0px 0px 5px' },
    { title: 'Stock On Hand', icon: <NearMeOutlined />, route: appRoutes.STORE_ORDERS, backgroundColor: '#d2cef1', margin: '10px 5px 0px 0px' },
    { title: 'Track Order', icon: <FormatListBulletedOutlined />, route: appRoutes.MY_COUNTER, backgroundColor: '#f2e4db', margin: '10px 0px 0px 5px' },
    { title: 'My Sell', icon: <FormatListBulletedOutlined />, route: appRoutes.MY_SELL, backgroundColor: '#c2d6f1', margin: '10px 5px 0px 0px' },
    { title: 'Stats', icon: <FormatListBulletedOutlined />, route: appRoutes.STATS, backgroundColor: '#f0dfa9', margin: '10px 0px 0px 5px' }];

    return (
        <React.Fragment style={{ backgroundColor: '#f1f5f8' }}>
            <AppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#f1f5f8' }}>
                <Toolbar style={{ minHeight: '46px' }}>
                    <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%', whiteSpace: 'nowrap', color: 'black', fontSize: '20px' }}>
                        <b>{homeData.storeName ? homeData.storeName.name : 'आपले ई-कृषी काउंटर'}</b></Typography>
                    {/* {storeUser && <div style={{ textAlign: 'right' }}>
                        <IconButton onClick={handleMenu} style={{ textAlign: 'right' }}>
                            <AccountCircle style={{ color: 'black' }} />
                        </IconButton>
                        <Menu anchorEl={anchorEl} style={{ padding: '0px' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={open} onClose={handleClose}>
                            <MenuItem style={{ padding: '0px 5px', minHeight: '30px', backgroundColor: '#FF5722' }} onClick={(e) => viewProfile()}>Profile</MenuItem>
                            <MenuItem style={{ padding: '0px 5px', minHeight: '30px', backgroundColor: '#FF5722' }} onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </div>} */}
                </Toolbar>
            </AppBar>
            {/* Promotional Offers Container*/}
            <Grid container style={{ padding: '0px', backgroundColor: '#f1f5f8' }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PromotionalOffer />
                </Grid>
            </Grid>
            <Grid container direction="row" style={{ padding: '10px', backgroundColor: '#f1f5f8' }}>
                {homeOptions.map((option) => {
                    return (
                        <Grid item xs={6} sm={6} md={6}>
                            <Link to={option.route} style={{ textDecoration: 'none' }}>
                                <Card style={{
                                    margin: option.margin, backgroundColor: option.backgroundColor,
                                    height: '120px', border: '1px solid #cecece', boxShadow: 'none'
                                }}>
                                    <CardContent style={{ textAlign: 'center', paddingTop: '5px' }}>
                                        <Avatar style={{ margin: '0 auto', height: '60px', width: '60px', color: 'black', backgroundColor: 'white' }}>{option.icon}</Avatar>
                                        <Typography style={{ color: 'black', fontSize: '100%', marginTop: '5px' }}>
                                            <b>{option.title}</b>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    )
                })}
            </Grid>
            <div style={{ marginBottom: '35px' }}></div>
            <Grid container alignItems="center" justify="flex-start" className={classes.fab}>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: (bgColor == 1 ? '#001dbb' : 'transparent') }}><HomeIcon style={{ color: '#fff', fontSize: '28px' }} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: (bgColor == 2 ? '#001dbb' : 'transparent') }}><Notifications style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(2)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: (bgColor == 3 ? '#001dbb' : 'transparent') }}><ShoppingBasket style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(3)} /></Button>
                </Grid>
                <Grid item style={{ width: '25%', textAlign: 'center' }}>
                    <Button style={{ padding: '6px', backgroundColor: (bgColor == 4 ? '#001dbb' : 'transparent') }}><Dehaze style={{ color: '#fff', fontSize: '28px' }} onClick={(e) => renderComponent(4)} /></Button>
                </Grid>
            </Grid >
        </React.Fragment >
    );
}

export default Home;